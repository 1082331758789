import Vue from 'vue'
import VueI18n from 'vue-i18n'
import vnMessage from './vn.json'
import enMessage from './en.json'

Vue.use(VueI18n)

const messages = {
  vi: vnMessage,
  en: enMessage,
}

export default new VueI18n({
  locale: getBrowserLocale(),
  fallbackLocale: 'vi',
  messages,
})

function getBrowserLocale() {
  const navigatorLocale =
    navigator.languages !== undefined ?
    navigator.languages[0] :
    navigator.language

  if (!navigatorLocale) {
    return undefined
  }
  
  return navigatorLocale.trim().split(/-|_/)[0]
}