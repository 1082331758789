<template>
  <router-view/>
</template>

<script>
export default {
  name: 'app',
  methods: {
    outoLogout: () => {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      window.location.href = "/login"
    }
  }
}
</script>

<style lang="scss">
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
  @import '~vue-search-select/dist/VueSearchSelect.css';

  // Import required dependencies
  @import '~bootstrap/dist/css/bootstrap.css';
  // Import date picker css
  @import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
  @import '~vue-loading-overlay/dist/vue-loading.css';
  @import '~vue-select/dist/vue-select.css';
</style>
<style>
  .c-main{
    padding-top: 0.5rem !important;
    padding-left: 0.1rem !important;
  }
  .container-fluid{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .div-scroll {
    max-height:344px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .div-card-scroll {
    max-height:580px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .row-no-padding > [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .v-money{
    max-height: 28.38px;
  }
  .date-picker-height{
    max-height: 28.38px;
  }
  .form-fixer {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .form-fixer-list{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .form-group {
    margin-bottom: 0.2rem;
  }
</style>
<style>
.card-body-create{
  margin-top: -1rem !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

