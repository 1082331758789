import Axios from 'axios';
import apiUrl from '../utils/api';
import { authHeader } from '../helpers';

export const categoryService = {
    get_ls_category,
    create,
    update,
    get_ls_category_ddl,
    name_unique,
    delete_obj,
    recover_obj
};

function delete_obj(id) {
    return Axios.delete(`${apiUrl}/category-delete?id=${id}`, {headers: authHeader()})
}
function recover_obj(id) {
    return Axios.delete(`${apiUrl}/category-recover?id=${id}`, {headers: authHeader()})
}

function name_unique(name){
    return Axios.get(`${apiUrl}/category-unique?name=${name}`,{headers: authHeader()})
}

function get_ls_category(param) {
    const requestOptions = {
        headers: authHeader(),
        params:param
    };
    return Axios.get(`${apiUrl}/category-list`,requestOptions)
}

function get_ls_category_ddl(){
    return Axios.get(`${apiUrl}/category-ddl`, {headers: authHeader()})
}

function create(category) {
    return Axios.post(`${apiUrl}/category/create`,category,{headers:authHeader()})
}

function update(category) {
  return Axios.post(`${apiUrl}/category/update/${category.id}`,category,{headers:authHeader()})
}
