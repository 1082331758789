import { carService } from '../services'

const http_get_ls_car = async (params) => {
  const { data } = await carService.get_ls_car(params)
  return data
}

export const st_car = {
  namespaced: true,
  state: {
    ls_car: [],
    ls_car_ddl: [],
    page: 1,
    page_count: 0,
    filter: null,
    per_page:10,
    lang:'en'
  },
  getters:{
    ls_car: state => state.ls_car,
    ls_car_ddl: state => state.ls_car_ddl,
    page: state => state.page,
    page_count: state => state.page_count,
    filter: state => state.filter,
    per_page:state=>state.per_page
  },
  actions: {
    async delete_obj({commit},id){
      return await carService.delete_obj(id)
    },
    get_ls_car_ddl ({ state, commit },param) {
      carService.get_ls_car_ddl(param)
      .then(
        car => commit('set_ls_car_ddl', car)
      )
    },

    async recover_obj({commit},id){
      return await carService.recover_obj(id)
    },
    async create({commit},car){
      return await carService.create(car)
    },

    async update({commit},car){
      return await carService.update(car)
    },

    async get_ls_car ({ state, commit }) {
      const data = await http_get_ls_car({
        lang:state.lang,
        per_page:state.per_page,
        page: state.page,
        filter: state.filter
      })

      commit('set_ls_car', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page ({ state, commit }, page) {
      const data = await http_get_ls_car({
        lang: state.lang,
        per_page:state.per_page,
        page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_car', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }) {
      debugger
      const data = await http_get_ls_car({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_car',
      {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    }
  },
  mutations: {
    set_ls_car (state, { items, pages,per_page }) {
      state.ls_car = items
      state.page_count = pages
      state.per_page = per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    },
    set_ls_car_ddl(state, items) {
      state.ls_car_ddl = items.data
    }
  }
}
