import { categoryService } from '../services'

export const st_category = {
    namespaced: true,
    state: {
        ls_category: [],
        page: 1,
        page_count: 0,
        filter: null,
        ls_category_ddl:[],
        per_page:10
    },
    getters:{
        users: state => state.users,
        page: state => state.page,
        page_count: state => state.page_count,
        filter: state => state.filter,
        per_page:state=>state.per_page
    },
    actions: {
      async delete_obj({commit},id){
        return await categoryService.delete_obj(id)
      },
      async recover_obj({commit},id){
        return await categoryService.recover_obj(id)
      },
      async name_unique({ rootState, commit },phone){
        return await categoryService.name_unique(phone)
      },

      async create({commit},user){
        return await categoryService.create(user)
      },

      async update({commit},user){
        return await categoryService.update(user)
      },

      async get_ls_category ({ state, commit }) {
        const data = await http_get_ls_category({
          lang: state.lang,
          per_page:state.per_page,
          page: state.page,
          text: state.filter
        })
        commit('set_ls_category', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
          per_page:data.paginated.per_page})
      },

      get_ls_category_ddl(){
        categoryService.category_ddl()
          .then(
              cat => commit('set_ls_category_ddl', cat)
          );
      }
    },
    mutations: {
      set_ls_category_ddl (state, categories) {
        state.category_ddl = categories
      }
    }
  }
