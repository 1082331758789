
import { importService } from '../services'
import Vue from 'vue'

const http_get_ls_import = async (params) => {
  const { data } = await importService.get_ls_import(params)
  return data
}

export const st_import = {
  namespaced: true,
  state: {
    ls_import: [],
    page: 1,
    page_count: 0,
    filter: null,
    per_page:10
  },
  getters:{
    ls_import: state => state.ls_import,
    page: state => state.page,
    filter: state => state.filter,
    page_count: state => state.page_count,
    filter: state => state.filter,
    per_page:state=>state.per_page
  },
  actions: {
    async name_unique({commit},param){
      return await importService.name_unique(param)
    },
    async code_unique({commit},param){
      return await importService.code_unique(param)
    },
    async create({commit},item){
      return await importService.create(item)
    },

    async update({commit},data){
      return await importService.update(data)
    },

    async get_ls_import ({ state, commit }) {
      const data = await http_get_ls_import({
        lang: state.lang,
        per_page:state.per_page,
        page: state.page,
        text: state.filter
      })
      commit('set_ls_import', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page ({ state, commit }, page) {
      const data = await http_get_ls_import({
        lang: state.lang,
        per_page:state.per_page,
        page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_import', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }) {
      const data = await http_get_ls_import({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_import', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async delete_obj({commit},id){
      return await importService.delete_obj(id)
    },

    async recover_obj({commit},id){
      let co= await importService.recover_obj(id)
      return co
    },

    async bill_pdf({ commit },param){
      var loader = Vue.$loading.show()
      let temp= await importService.bill_pdf(param)
      loader.hide()
      return temp
    }
  },
  mutations: {
    set_ls_import (state, { items, pages,per_page }) {
      state.ls_import = items
      state.page_count = pages
      state.per_page=per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    }
  }
}
