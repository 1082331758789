import { clientService} from '../services'
import Vue from 'vue'

const http_get_ls_client = async (params) => {
  const { data } = await clientService.get_ls_client(params)
  return data
}

export const st_client = {
  namespaced: true,
  state: {
    ls_client: [],
    ls_client_ddl:[],
    ls_client_delivery_ddl:[],
    ls_client_return_ddl:[],
    page_client: 1,
    per_page_client:10,
    page_count_client: 0,
    filter_client: null,
    lang:'en'
  },
  getters:{
    ls_client: state => state.ls_client,
    ls_client_ddl: state => state.ls_client_ddl,
    ls_client_delivery_ddl: state => state.ls_client_delivery_ddl,
    ls_client_return_ddl: state => state.ls_client_return_ddl,

    page_client: state => state.page_client,
    page_count_client: state => state.page_count_client,
    per_page_client: state => state.per_page_client,
    filter_client: state => state.filter_client
  },
  actions: {
    get_ls_client_delivery_ddl({state, commit},param){
      clientService.get_ls_client_delivery_ddl(param)
      .then(
        cl => commit('set_ls_client_delivery_ddl', cl)
      )
    },
    get_ls_client_return_ddl({state, commit},param){
      clientService.get_ls_client_return_ddl(param)
      .then(
        cl => commit('set_ls_client_return_ddl', cl)
      )
    },
    get_ls_client_ddl({state, commit},param){
      clientService.get_ls_client_ddl(param)
      .then(
        cl => commit('set_ls_client_ddl', cl)
      )
    },
    async delete_obj({commit},id){
      return await clientService.delete_obj(id)
    },

    async recover_obj({commit},id){
      return await clientService.recover_obj(id)
    },

    async phone_unique({ rootState, commit },param){
      return await clientService.phone_unique(param)
    },

    async cmnd_unique({ rootState, commit },param){
      return await clientService.cmnd_unique(param)
    },

    async email_unique({ rootState, commit },param){
      return await clientService.email_unique(param)
    },

    async create({commit},client){
      return await clientService.create(client)
    },

    async update({commit},client){
      return await clientService.update(client)
    },

    async update_profile({commit},client){
      return await clientService.update_profile(client)
    },

    async get_ls_client ({ state, commit }) {
      var loader = Vue.$loading.show()
      const data = await http_get_ls_client({
        lang:state.lang,
        page: state.page_client,
        per_page:state.per_page_client,
        text: state.filter_client
      })
      debugger
      commit('set_ls_client', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
      loader.hide()
    },

    async change_page_client ({ state, commit }, page) {
      var loader = Vue.$loading.show()
      const data = await http_get_ls_client({
        page:page,
        lang: state.lang,
        per_page:state.per_page_client,
        text: state.filter_client
      })
      commit('set_page', page)
      commit('set_ls_client', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
      loader.hide()
    },

    async apply_filter_client ({ state, commit }) {
      var loader = Vue.$loading.show()
      const data = await http_get_ls_client({
        lang: state.lang,
        per_page:state.per_page_client,
        page: 1,
        text: state.filter_client
      })
      commit('set_page', 1)
      commit('set_ls_client', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
      loader.hide()
    }
  },
  mutations: {
    set_ls_client (state, { items, pages,per_page }) {
      state.ls_client = items
      state.page_count_client = pages
      state.per_page_client=per_page
    },
    set_per_page (state, set_page) {
      state.per_page_client = set_page
    },
    set_page (state, page) {
        state.page_client = page
    },
    set_filter (state, filter) {
        state.filter_client = filter
    },
    set_ls_client_delivery_ddl(state, items) {
      state.ls_client_delivery_ddl = items.data
    },
    set_ls_client_return_ddl(state, items) {
      state.ls_client_return_ddl = items.data
    },
    set_ls_client_ddl(state, items) {
      state.ls_client_ddl = items.data
    }
  }
}
