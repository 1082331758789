import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const rentService = {
  get_ls_rent,
  get_by_id,
  create,
  update,
  delete_obj,
  recover_obj,
  name_unique,
  get_ls_rent_ddl,
  get_ls_rent_by_room_type
}

function name_unique(param){
  debugger
  return Axios.get(`${apiUrl}/rent/name-unique/${param.company_id}?business=${param.business}&name=${param.name}`,{headers: authHeader()})
}

function get_ls_rent_by_room_type(param){
  debugger
  return Axios.get(`${apiUrl}/rent/get-by-room-type/${param.company_id}?type_id=${param.type_id}`,{headers: authHeader()})
}

function get_ls_rent_ddl(param){
  debugger
  return Axios.get(`${apiUrl}/rent/ddl/${param.company_id}?business=${param.business}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/rent/delete/${id}`,{headers: authHeader()})
}

function recover_obj(id) {
  return Axios.delete(`${apiUrl}/rent/recover/${id}`,{headers: authHeader()})
}

function get_ls_rent(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/rent/list`,requestOptions)
}

function get_by_id(id) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  }
  return Axios.get(`${apiUrl}/rent/one/${id}`, requestOptions)
}

function create(rent) {
  return Axios.post(`${apiUrl}/rent/create`,rent,{headers:authHeader()})
}

function update(rent) {
  return Axios.put(`${apiUrl}/rent/update/${rent.id}`,rent,{headers:authHeader()})
}
