import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const otherService = {
    get_ls_other,
    get_by_id,
    create,
    update,
    delete_obj,
    recover_obj,
    name_unique,
    get_ls_other_ddl,
    bill_pdf
}

function name_unique(param){
  debugger
  return Axios.get(`${apiUrl}/other-outcome/name-unique/${param.company_id}?business=${param.business}&name=${param.name}&lang=${param.lang}`,{headers: authHeader()})
}

function get_ls_other_ddl(param){
  debugger
  return Axios.get(`${apiUrl}/other-outcome/ddl/${param.company_id}?business=${param.business}&lang=${param.lang}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/other-outcome/delete/${id}`,{headers: authHeader()})
}

function recover_obj(id) {
  return Axios.delete(`${apiUrl}/other-outcome/recover/${id}`,{headers: authHeader()})
}

function get_ls_other(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/other-outcome/list`,requestOptions)
}

function get_by_id(id) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return Axios.get(`${apiUrl}/other-outcome/one/${id}`, requestOptions)
}

function create(other) {
  return Axios.post(`${apiUrl}/other-outcome/create`,other,{headers:authHeader()})
}

function update(other) {
  return Axios.put(`${apiUrl}/other-outcome/update/${other.data.id}`,other,{headers:authHeader()})
}

function bill_pdf(param) {
  const requestOptions = {
    method: 'GET',
    responseType: 'arraybuffer',
    headers: authHeader()
  }
  return Axios.get(`${apiUrl}/other-outcome/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
}
