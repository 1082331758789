import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const typeService = {
  get_ls_type,
  get_by_id,
  create,
  update,
  delete_obj,
  recover_obj,
  get_ls_type_ddl,
  name_unique,
  code_unique
}

function name_unique(param){
  return Axios.get(`${apiUrl}/type/name-unique/${param.company_id}?name=${param.name}`,{headers: authHeader()})
}

function code_unique(param){
  return Axios.get(`${apiUrl}/type/code-unique/${param.company_id}?code=${param.code}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/type/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/type/recover/${id}`,{headers: authHeader()})
}

function get_ls_type(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/type/list`,requestOptions)
}

function create(type) {
  return Axios.post(`${apiUrl}/type/create`,type,{headers:authHeader()})
}

function update(type) {
  return Axios.put(`${apiUrl}/type/update/${type.id}`,type,{headers:authHeader()})
}

function get_by_id(id) {
  return Axios.get(`${apiUrl}/type/get-by-id/${id}`,{headers: authHeader()})
}

function get_ls_type_ddl(company_id){
  debugger
  return Axios.get(`${apiUrl}/type/ddl/${company_id}`,{headers: authHeader()})
}

