import { otoService } from '../services'
import { authHeader } from '../helpers'
import Vue from 'vue'

const http_get_ls_oto = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  const { data } = await otoService.get_ls_oto(params)
  return data
}

export const st_oto = {
  namespaced: true,
  state: {
    ls_oto: [],
    page: 1,
    page_count: 0,
    filter: null,
    per_page:10,
    lang:'en'
  },
  getters:{
    ls_oto: state => state.ls_oto,
    page: state => state.page,
    page_count: state => state.page_count,
    filter: state => state.filter,
    per_page:state=>state.per_page
  },
  actions: {
    async delete_obj({commit},id){
      return await otoService.delete_obj(id)
    },

    async recover_obj({commit},id){
      return await otoService.recover_obj(id)
    },

    async phone_unique({ rootState, commit },phone){
      return await otoService.phone_unique(phone)
    },
    async create({commit},param){
      var loader = Vue.$loading.show()
      let create= await otoService.create(param)
      loader.hide()
      return create
    },

    async update({commit},param){
      var loader = Vue.$loading.show()
      let update= await otoService.update(param)
      loader.hide()
      return update
    },

    async get_ls_oto ({ state, commit }) {
      const data = await http_get_ls_oto({
        lang:state.lang,
        per_page:state.per_page,
        page: state.page,
        filter: state.filter
      })

      commit('set_ls_oto', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page ({ state, commit }, page) {
      const data = await http_get_ls_oto({
        lang: state.lang,
        per_page:state.per_page,
        page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_oto', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }) {
      debugger
      const data = await http_get_ls_oto({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_oto', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async bill_pdf({ commit },param){
      var loader = Vue.$loading.show()
      let pdf= await otoService.bill_pdf(param)
      loader.hide()
      return pdf
    },
    async release_object({commit},param){
      var loader = Vue.$loading.show()
      let res= await otoService.release_object(param)
      loader.hide()
      return res
    }
  },
  mutations: {
    set_ls_oto (state, { items, pages,per_page }) {
      state.ls_oto = items
      state.page_count = pages
      state.per_page = per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    }
  }
}
