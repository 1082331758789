import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const doctorService = {
  get_ls_doctor,
  create,
  update,
  phone_unique,
  email_unique,
  delete_obj,
  recover_obj
}

function email_unique(param){
  return Axios.get(`${apiUrl}/doctor/email-unique/${param.company_id}?email=${param.email}`,{headers: authHeader()})
}

function phone_unique(param){
  return Axios.get(`${apiUrl}/doctor/phone-unique/${param.company_id}?phone=${param.phone}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/doctor/delete/${id}`,{headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/doctor/recover/${id}`,{headers: authHeader()})
}

function get_ls_doctor(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  };
  return Axios.get(`${apiUrl}/doctor/list`,requestOptions)
}

function create(doctor) {
  return Axios.post(`${apiUrl}/doctor/create`,doctor,{headers:authHeader()})
}

function update(doctor) {
  return Axios.put(`${apiUrl}/doctor/update/${doctor.id}`,doctor,{headers:authHeader()})
}
