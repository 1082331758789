import { userService} from '../services'
import Vue from 'vue'
import app from '../main'
import { Lang } from '@/helpers/lang.js'

const http_get_ls_user = async (params) => {
  const { data } = await userService.get_ls_user(params)
  return data
}

export const st_user = {
  namespaced: true,
  state: {
    ls_user: [],
    ls_user_ddl: [],
    current_page: 1,
    per_page:10,
    total_row: 0,
    filter: null,
    lang:Lang.vi
  },
  getters:{
    ls_user: state => state.ls_user,
    ls_user_ddl: state => state.ls_user_ddl,
    current_page: state => state.current_page,
    total_row: state => state.total_row,
    per_page: state => state.per_page,
    filter: state => state.filter
  },
  actions: {
    get_ls_user_ddl ({ state, commit },company_id) {
      userService.get_ls_user_ddl(company_id)
      .then(
        company => commit('set_ls_user_ddl', company)
      )
    },
    setLang({commit}, lang) {
      commit('set_lang', lang)
    },
    async register_ok_and_send_email({commit},param){
      return await userService.send_email(param)
    },

    async confirm_email({commit},token){
      return await userService.confirm_email(token)
    },

    async delete_obj({commit},id){
      return await userService.delete_obj(id)
    },

    async recover_obj({commit},id){
      return await userService.recover_obj(id)
    },

    async phone_unique({ rootState, commit },param){
      return await userService.phone_unique(param)
    },

    async profile_phone_unique({ rootState, commit },param){
      return await userService.profile_phone_unique(param)
    },

    async register_phone_unique({ rootState, commit },phone){
      return await userService.register_phone_unique(phone)
    },

    async code_unique({ rootState, commit },code){
      return await userService.code_unique(code)
    },

    async email_unique({ rootState, commit },email){
      return await userService.email_unique(email)
    },

    async update({commit},user){
      var loader = Vue.$loading.show()
      let res= await userService.update(user)
      loader.hide()
      return res
    },

    async create({commit},user){
      var loader = Vue.$loading.show()
      let res= await userService.create(user)
      loader.hide()
      return res
    },

    async update_profile({commit},user){
      return await userService.update_profile(user)
    },

    async get_ls_user ({ state, commit }) {
      var loader = Vue.$loading.show()
      const data = await http_get_ls_user({
        lang:state.lang,
        page: state.current_page,
        per_page:state.per_page,
        text: state.filter
      })
      commit('set_ls_user', {items:data.data,total_row:data.paginated.total,
        per_page:data.paginated.per_page})
      loader.hide()
    },

    async change_page ({ state, commit }, current_page) {
      var loader = Vue.$loading.show()
      const data = await http_get_ls_user({
        page:current_page,
        lang: state.lang,
        per_page:state.per_page,
        text: state.filter
      })
      commit('set_page', current_page)
      commit('set_ls_user', {items:data.data,total_row:data.paginated.total,
        per_page:data.paginated.per_page})
      loader.hide()
    },

    async apply_filter ({ state, commit }) {
      var loader = Vue.$loading.show()
      const data = await http_get_ls_user({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_user', {items:data.data,total_row:data.paginated.total,
        per_page:data.paginated.per_page})
      loader.hide()
    },

    async xem_bao_cao_tong({ state, commit },params){
      return await userService.xem_bao_cao_tong(params)
    },

    async dentist_doanhthutheongay({ state, commit },params){
      return await userService.dentist_doanhthutheongay(params)
    }
  },
  mutations: {
    set_lang (state, lang) {
      app.$i18n.locale = lang
    },
    set_ls_user (state, { items, total_row, per_page }) {
      state.ls_user = items
      state.total_row = total_row
      state.per_page=per_page
    },
    set_page (state, current_page) {
      state.current_page = current_page
    },
    set_filter (state, filter) {
      state.filter = filter
    },
    set_per_page (state, per_page) {
      state.per_page = per_page
    },
    set_ls_user_ddl(state, items) {
      state.ls_user_ddl = items.data
    }
  }
}
