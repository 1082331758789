import Axios from 'axios'
import axios from "axios"
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const userService = {
  login,
  register,
  get_ls_user,
  get_user_by_id,
  create,
  update,
  xem_bao_cao_tong,
  phone_unique,
  email_unique,
  delete_obj,
  recover_obj,
  reset_password,
  update_profile,
  dentist_doanhthutheongay,
  code_unique,
  register_phone_unique,
  profile_phone_unique,
  send_email,
  confirm_email,
  get_ls_user_ddl,
  log_logout
}

function get_ls_user_ddl(param){
  debugger
  if(param.branch_id){
    return Axios.get(`${apiUrl}/user/ddl/${param.company_id}?branch_id=${param.branch_id}`,{headers: authHeader()})
  }else{
    return Axios.get(`${apiUrl}/user/ddl/${param.company_id}`,{headers: authHeader()})
  }
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/user/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/user/recover/${id}`, {headers: authHeader()})
}

function phone_unique(param){
  return Axios.get(`${apiUrl}/user/phone-unique?phone=${param.phone}&company_id=${param.company_id}&branch_id=${param.branch_id}`,{headers: authHeader()})
}

function profile_phone_unique(param){
  return Axios.get(`${apiUrl}/user/profile-phone-unique?phone=${param.phone}&company_id=${param.company_id}&branch_id=${param.branch_id}`,{headers: authHeader()})
}

function register_phone_unique(phone){
  return Axios.get(`${apiUrl}/user/register-phone-unique?phone=${phone}`,{headers: authHeader()})
}

function email_unique(email){
  return Axios.get(`${apiUrl}/user/email-unique?email=${email}`,{headers: authHeader()})
}

function code_unique(code){
  return Axios.get(`${apiUrl}/user/code-unique?code=${code}`,{headers: authHeader()})
}

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  }

  let url=`${apiUrl}/user/login`
  return axios.post(url,{'email':email,'password':password},requestOptions)
}

function log_logout(param) {
  return Axios.post(`${apiUrl}/user/logout`,param,{headers: authHeader()})
}

function register(param) {
  return Axios.post(`${apiUrl}/user/register`,param,{headers: authHeader()})
}

function reset_password(user){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return Axios.post(`${apiUrl}/user/reset-password`,user,requestOptions)
}

function xem_bao_cao_tong(params) {
  const requestOptions = {
      method: 'POST',
      responseType: 'arraybuffer',
      headers: authHeader()
  }
  return Axios.post(`${apiUrl}/user/bao-cao-tong`,params,requestOptions)
}

function get_ls_user(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }

  return Axios.get(`${apiUrl}/user/list`,requestOptions)
}

function get_user_by_id(id) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  }
  return Axios.get(`${apiUrl}/user/${id}`, requestOptions)
}

function send_email(param) {
  return Axios.post(`${apiUrl}/user/send-email`,param,{headers:authHeader()})
}

function confirm_email(token) {
  return Axios.get(`${apiUrl}/user/confirm-email/${token}`,{headers:authHeader()})
}

function create(user) {
  return Axios.post(`${apiUrl}/user/create`,user,{headers:authHeader()})
}

function update(user) {
  return Axios.put(`${apiUrl}/user/update/${user.id}`,user,{headers:authHeader()})
}

function update_profile(user) {
  debugger
  return Axios.post(`${apiUrl}/user/profile/${user.id}`,user,{headers:authHeader()})
}

function dentist_doanhthutheongay(params){
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
    params:params
  }

  return Axios.post(`${apiUrl}/dentist/doanh-thu-ngay`,params,requestOptions)
}
