import { packageService } from '../services'

const http_get_ls_package = async (params) => {
  const { data } = await packageService.get_ls_package(params)
  return data
}

export const st_package = {
    namespaced: true,
    state: {
      ls_package:[],
      ls_package_ddl:[],
      page: 1,
      per_page:10,
      page_count: 0,
      filter: null
    },
    getters:{
      page: state => state.page,
      ls_package_ddl: state => state.ls_package_ddl,
      page_count: state => state.page_count,
      filter: state => state.filter,
      per_page:state=>state.per_page,
      ls_package:state=>state.ls_package
    },
    actions: {
      async delete_obj({commit},id){
        return await packageService.delete_obj(id)
      },

      async recover_obj({commit},id){
        return await packageService.recover_obj(id)
      },

      async name_unique({commit},params){
        return await packageService.name_unique(params)
      },

      async code_unique({commit},params){
        return await packageService.code_unique(params)
      },

      async create({commit},data){
        let res= await packageService.create(data)
        debugger
        return res
      },

      async update({commit},data){
        let res= await packageService.update(data)
        debugger
        return res
      },

      async get_ls_package ({ state, commit }) {
        const data = await http_get_ls_package({
          lang: state.lang,
          per_page:state.per_page,
          page: state.page,
          text: state.filter
        })
        commit('set_ls_package', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
          per_page:data.paginated.per_page})
      },

      async change_page ({ state, commit }, page) {
        const data = await http_get_ls_package({
          lang: state.lang,
          per_page:state.per_page,
          page,
          text: state.filter
        })

        commit('set_page', page)
        commit('set_ls_package', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
          per_page:data.paginated.per_page})
      },

      async apply_filter ({ state, commit }, filter) {
        const data = await http_get_ls_package({
          lang: state.lang,
          per_page:state.per_page,
          page: 1,
          text: filter
        })
        commit('set_page', 1)
        commit('set_ls_package', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
          per_page:data.paginated.per_page})
      },

      async get_by_id ({ state, commit },id) {
        return await packageService.get_by_id(id)
      },

      get_ls_package_ddl ({ state, commit }) {
        packageService.get_ls_package_ddl()
        .then(
          pk => commit('set_ls_package_ddl', pk)
        )
      }
    },
    mutations: {
      set_ls_package (state, { items, pages,per_page }) {
        state.ls_package = items
        state.page_count = pages
        state.per_page=per_page
      },
      set_page (state, page) {
        state.page = page
      },
      set_filter (state, filter) {
        state.filter = filter
      },
      set_ls_package_ddl(state, items) {
        state.ls_package_ddl = items.data
      }
    }
}
