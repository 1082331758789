import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const packageService = {
  get_ls_package,
  get_by_id,
  create,
  update,
  delete_obj,
  recover_obj,
  get_ls_package_ddl,
  name_unique,
  code_unique
}

function name_unique(param){
  return Axios.get(`${apiUrl}/package/name-unique?name=${param.name}&company_id=${param.company_id}`,{headers: authHeader()})
}

function code_unique(param){
  return Axios.get(`${apiUrl}/package/code-unique?code=${param.code}&company_id=${param.company_id}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/package/delete?id=${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/package/recover?id=${id}`,{headers: authHeader()})
}

function get_ls_package(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/package/list`,requestOptions)
}

function create(data) {
  return Axios.post(`${apiUrl}/package/create`,data,{headers:authHeader()})
}

function update(data) {
  return Axios.put(`${apiUrl}/package/update`,data,{headers:authHeader()})
}

function get_by_id(id) {
  return Axios.get(`${apiUrl}/package/get-by-id?id=${id}`,{headers: authHeader()})
}

function get_ls_package_ddl(){
  return Axios.get(`${apiUrl}/package/ddl`,{headers: authHeader()})
}
