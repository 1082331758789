import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const clientService = {
  get_ls_client,
  get_ls_client_ddl,
  get_client_by_id,
  create,
  update,
  phone_unique,
  cmnd_unique,
  email_unique,
  delete_obj,
  recover_obj,
  get_ls_client_name_suggest,
  get_ls_client_delivery_ddl,
  get_ls_client_return_ddl
}

function get_ls_client_name_suggest(param){
  debugger
  return Axios.get(`${apiUrl}/client/get-ls-client-name/${param.company_id}?branch_id=${param.branch_id}&name=${param.name}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/client/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/client/recover/${id}`, {headers: authHeader()})
}

function phone_unique(param){
  return Axios.get(`${apiUrl}/client/phone-unique/${param.company_id}?phone=${param.phone}`,{headers: authHeader()})
}

function cmnd_unique(param){
  return Axios.get(`${apiUrl}/client/cmnd-unique/${param.company_id}?cmnd=${param.cmnd}`,{headers: authHeader()})
}

function email_unique(param){
  return Axios.get(`${apiUrl}/client/email-unique/${param.company_id}?email=${param.email}`,{headers: authHeader()})
}

function get_ls_client(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }

  return Axios.get(`${apiUrl}/client/list`,requestOptions)
}

function get_ls_client_ddl(param) {
  debugger
  return Axios.get(`${apiUrl}/client/ddl/${param.company_id}?branch_id=${param.branch_id}`,{headers:authHeader()})
}

function get_ls_client_delivery_ddl(param) {
  debugger
  return Axios.get(`${apiUrl}/client-delivery/ddl/${param.company_id}?branch_id=${param.branch_id}`,{headers:authHeader()})
}

function get_ls_client_return_ddl(param) {
  debugger
  return Axios.get(`${apiUrl}/client-return/ddl/${param.company_id}?branch_id=${param.branch_id}`,{headers:authHeader()})
}

function get_client_by_id(id) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  }
  return Axios.get(`${apiUrl}/client/${id}`, requestOptions);
}

function create(client) {
  return Axios.post(`${apiUrl}/client/create`,client,{headers:authHeader()})
}

function update(client) {
  return Axios.put(`${apiUrl}/client/update/${client.id}`,client,{headers:authHeader()})
}
