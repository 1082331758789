import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const productService = {
  get_ls_product,
  create,
  update,
  get_ls_product_ddl,
  get_ls_order_object_product_ddl,
  delete_obj,
  recover_obj,
  name_unique,
  code_unique,
  get_ls_product_report
}

function name_unique(param){
  return Axios.get(`${apiUrl}/product/name-unique/${param.company_id}?product_id=${param.product_id}&name=${param.name}`,{headers: authHeader()})
}

function code_unique(param){
  return Axios.get(`${apiUrl}/product/code-unique/${param.company_id}?code=${param.code}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/product/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/product/recover/${id}`, {headers: authHeader()})
}

function get_ls_product(param) {
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/product/list/${param.type}`,requestOptions)
}

function get_ls_product_report(param) {
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/product/report-im-ex-re/${param.company_id}`,requestOptions)
}

function get_ls_product_ddl(param){
  debugger
  return Axios.get(`${apiUrl}/product/ddl/${param.company_id}?supply_id=${param.supply_id}&branch_id=${param.branch_id}&business=${param.business}`, {headers: authHeader()})
}

function get_ls_order_object_product_ddl(param){
  debugger
  return Axios.get(`${apiUrl}/order-object-product/ddl/${param.company_id}?order_id=${param.order_id}&branch_id=${param.branch_id}&object_id=${param.object_id}`, {headers: authHeader()})
}

function create(product) {
  return Axios.post(`${apiUrl}/product/create`,product,{headers:authHeader()})
}

function update(product) {
  return Axios.put(`${apiUrl}/product/update/${product.id}`,product,{headers:authHeader()})
}
