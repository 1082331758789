import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const companyService = {
    get_ls_company,
    create,
    update,
    get_ls_company_ddl,
    phone_unique,
    profile_phone_unique,
    code_unique,
    delete_obj,
    recover_obj,
    get_by_id,
    update_company_profile
};

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/company/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/company/recover/${id}`, {headers: authHeader()})
}

function profile_phone_unique(param){
  debugger
  return Axios.get(`${apiUrl}/company/profile-phone-unique/${param.company_id}?phone=${param.phone}`,{headers: authHeader()})
}

function phone_unique(phone){
  debugger
  return Axios.get(`${apiUrl}/company/phone-unique?phone=${phone}`,{headers: authHeader()})
}

function code_unique(code){
  return Axios.get(`${apiUrl}/company/code-unique?code=${code}`,{headers: authHeader()})
}

function get_ls_company(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/company/list`,requestOptions)
}

function get_ls_company_ddl(company_id){
  return Axios.get(`${apiUrl}/company/ddl/${company_id}`,{headers: authHeader()})
}

function create(company) {
  return Axios.post(`${apiUrl}/company/create`,company,{headers:authHeader()})
}

function update(company) {
  return Axios.put(`${apiUrl}/company/update/${company.id}`,company,{headers:authHeader()})
}

function get_by_id(id) {
  return Axios.get(`${apiUrl}/company/one/${id}`,{headers: authHeader()})
}

function update_company_profile(param) {
  return Axios.put(`${apiUrl}/company/update-profile/${param.data.id}`,param,{headers:authHeader()})
}
