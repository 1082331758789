import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const objectService = {
  get_ls_object,
  get_by_id,
  create,
  update,
  delete_obj,
  recover_obj,
  get_ls_object_ddl,
  get_ls_object_has_order_ddl,
  name_unique,
  code_unique,
  get_ls_object_name_suggest
}
function get_ls_object_name_suggest(param){
  debugger
  return Axios.get(`${apiUrl}/object/get-ls-object-name/${param.company_id}?branch_id=${param.branch_id}&name=${param.name}`,{headers: authHeader()})
}

function name_unique(param){
  return Axios.get(`${apiUrl}/object/name-unique/${param.company_id}?name=${param.name}`,{headers: authHeader()})
}

function code_unique(param){
  return Axios.get(`${apiUrl}/object/code-unique/${param.company_id}?code=${param.code}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/object/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/object/recover/${id}`,{headers: authHeader()})
}

function get_ls_object(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/object/list`,requestOptions)
}

function create(object) {
  return Axios.post(`${apiUrl}/object/create`,object,{headers:authHeader()})
}

function update(object) {
  return Axios.put(`${apiUrl}/object/update/${object.id}`,object,{headers:authHeader()})
}

function get_by_id(id) {
  return Axios.get(`${apiUrl}/object/get-by-id/${id}`,{headers: authHeader()})
}

function get_ls_object_ddl(param){
  debugger
  return Axios.get(`${apiUrl}/object/ddl/${param.company_id}?branch_id=${param.branch_id}`,{headers: authHeader()})
}

function get_ls_object_has_order_ddl(param){
  debugger
  return Axios.get(`${apiUrl}/object-has-order/ddl/${param.company_id}?branch_id=${param.branch_id}`,{headers: authHeader()})
}


