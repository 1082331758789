import { userService } from '../services'
import Vue from 'vue'

const user = JSON.parse(localStorage.getItem('user'))

export const st_authentication = {
  namespaced: true,
  state: {
    user: user
  },
  getters:{
    user: state => state.user
  },
  actions: {
    async login({ dispatch, commit }, { email, password }) {
      var loader = Vue.$loading.show()
      let login= await userService.login(email, password)
      loader.hide()
      return login
    },
    async log_logout({ commit }, param) {
      var loader = Vue.$loading.show()
      let logout= await userService.log_logout(param)
      loader.hide()
      return logout
    },
    do_logout({ commit }) {
      // remove user from local storage to log user out
      debugger
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh')
    },
    async reset_password({ commit },user) {
      var loader = Vue.$loading.show()
      let temp= await userService.reset_password(user)
      loader.hide()
      return temp
    },
    async dang_ky({ commit },param) {
      var loader = Vue.$loading.show()
      let regis= await userService.register(param)
      loader.hide()
      return regis
    },

    async update_profile({commit},user){
      var loader = Vue.$loading.show()
      let temp= await userService.update_profile(user)
      loader.hide()
      return temp
    }
  },
  mutations: {
    login_success(state, user) {
      debugger
      state.user = user
    },
    login_failure(state) {
      state.user = null
    },
    logout(state) {
      state.user = null
    }
  }
}
