import { utilService } from '../services'

export const utils = {
    namespaced: true,
    state: {
        ls_loduthuong:[]
    },
    actions: {
      get_ls_loduthuong({ commit}){
        utilService.get_ls_loduthuong()
        .then(
            lo => commit('set_ls_loduthuong', lo)
        );
      },

      server_time({ commit}){
        return utilService.server_time()
      }
    },
    mutations: {
      set_ls_loduthuong (state, items) {
          state.ls_loduthuong = items
      }
    }
}
