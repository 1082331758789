import { productService } from '../services'

const http_get_ls_product = async (params) => {
  const { data } = await productService.get_ls_product(params)
  return data
}

export const st_product = {
  namespaced: true,
  state: {
    ls_product: [],
    page: 1,
    page_count: 0,
    filter: null,
    ls_product_ddl:[],
    ls_order_object_product_ddl:[],
    per_page:10,
    type:1
  },
  getters:{
    ls_product: state => state.ls_product,
    ls_product_ddl: state => state.ls_product_ddl,
    ls_order_object_product_ddl: state => state.ls_order_object_product_ddl,
    page: state => state.page,
    filter: state => state.filter,
    page_count: state => state.page_count,
    per_page:state=>state.per_page,
    type:state=>state.type
  },
  actions: {
    get_ls_product_ddl({state, commit},param){
      productService.get_ls_product_ddl(param)
      .then(
        sp => commit('set_ls_product_ddl', sp)
      )
    },
    async get_ls_order_object_product_ddl({state, commit},param){
      return await productService.get_ls_order_object_product_ddl(param)
    },
    async get_ls_product_ddl_async({state, commit},param){
      let products= await productService.get_ls_product_ddl(param)
      return products
    },
    async name_unique({commit},param){
      return await productService.name_unique(param)
    },
    async code_unique({commit},param){
      return await productService.code_unique(param)
    },
    async create({commit},product){
      return await productService.create(product)
    },

    async update({commit},product){
      return await productService.update(product)
    },

    async get_ls_product ({ state, commit }) {
      const data = await http_get_ls_product({
        lang: state.lang,
        per_page:state.per_page,
        page: state.page,
        text: state.filter,
        type:state.type
      })
      commit('set_ls_product', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page ({ state, commit }, page) {
      const data = await http_get_ls_product({
        lang: state.lang,
        per_page:state.per_page,
        page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_product', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }) {
      const data = await http_get_ls_product({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_product', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async delete_obj({commit},id){
      return await productService.delete_obj(id)
    },

    async recover_obj({commit},id){
      let co= await productService.recover_obj(id)
      return co
    }
  },
  mutations: {
    set_ls_product (state, { items, pages,per_page }) {
      state.ls_product = items
      state.page_count = pages
      state.per_page=per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    },
    set_ls_product_ddl(state, items) {
      debugger
      state.ls_product_ddl = items.data
    },
    set_ls_order_object_product_ddl(state, items) {
      debugger
      state.ls_order_object_product_ddl = items.data
    },
    set_type (state, val) {
      state.type = val
    }
  }
}
