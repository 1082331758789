import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const staffService = {
    get_ls_staff,
    get_by_id,
    create,
    update,
    delete_obj,
    get_ls_staff_ddl
}

function get_ls_staff_ddl(param){
  debugger
  return Axios.get(`${apiUrl}/staff/ddl/${param.company_id}?branch_id=${param.branch_id}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/staff/delete/${id}`,{headers: authHeader()})
}

function get_ls_staff(param) {
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/staff/list`,requestOptions)
}

function get_by_id(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return Axios.get(`${apiUrl}/staff/one/${id}`, requestOptions)
}

function create(staff) {
  return Axios.post(`${apiUrl}/staff/create`,staff,{headers:authHeader()})
}

function update(staff) {
  return Axios.put(`${apiUrl}/staff/update/${staff.data.id}`,staff,{headers:authHeader()})
}
