import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const carService = {
  get_ls_car,
  get_by_id,
  create,
  update,
  delete_obj,
  recover_obj,
  get_ls_car_ddl
}

function get_ls_car_ddl(param){
  debugger
  return Axios.get(`${apiUrl}/car/ddl/${param.company_id}?branch_id=param${param.branch_id}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/car/delete/${id}`,{headers: authHeader()})
}

function recover_obj(id) {
  return Axios.delete(`${apiUrl}/car/recover/${id}`,{headers: authHeader()})
}

function get_ls_car(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/car/list`,requestOptions)
}

function get_by_id(id) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  }
  return Axios.get(`${apiUrl}/car/one/${id}`, requestOptions)
}

function create(car) {
  return Axios.post(`${apiUrl}/car/create`,car,{headers:authHeader()})
}

function update(car) {
  return Axios.put(`${apiUrl}/car/update/${car.id}`,car,{headers:authHeader()})
}
