import { productService,orderService } from '../services'
import moment from 'moment'
import Vue from 'vue'

const http_get_ls_product = async (params) => {
  const { data } = await productService.get_ls_product_report(params)
  return data
}

export const st_product_report = {
  namespaced: true,
  state: {
    ls_product: [],
    page: 1,
    page_count: 0,
    from_date:moment().add(-1,'month').format('DD-MM-YYYY'),
    to_date: moment().format('DD-MM-YYYY'),
    branchs:[],
    per_page:50,
    company_id:null,
    has_son:false
  },
  getters:{
    ls_product: state => state.ls_product,
    branchs: state => state.branchs,
    page: state => state.page,
    page_count: state => state.page_count,
    per_page:state=>state.per_page,
    from_date: state => state.from_date,
    to_date: state => state.to_date,
    company_id: state => state.company_id,
    has_son: state => state.has_son
  },
  actions: {
    async get_ls_product ({ state, commit }) {
      debugger
      let ids=null
      if(state.branchs.length>0){
        ids=state.branchs.map(function(item) {
          return item['id']
        }).join()
      }
      const data = await http_get_ls_product({
        lang: state.lang,
        from_date:state.from_date,
        to_date: state.to_date,
        branchs: ids,
        company_id: state.company_id,
        per_page:state.per_page,
        page: state.page
      })
      debugger
      commit('set_ls_product', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async change_page ({ state, commit }, page) {
      debugger
      let ids=null
      if(state.branchs.length>0){
        ids=state.branchs.map(function(item) {
          return item['id']
        }).join()
      }
      const data = await http_get_ls_product({
        lang: state.lang,
        from_date:state.from_date,
        to_date: state.to_date,
        branchs: ids,
        company_id: state.company_id,
        per_page:state.per_page,
        page
      })
      commit('set_page', page)
      commit('set_ls_product', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }) {
      debugger
      let ids=null
      if(state.branchs.length>0){
        ids=state.branchs.map(function(item) {
          return item['id']
        }).join()
      }
      const data = await http_get_ls_product({
        lang: state.lang,
        from_date:state.from_date,
        to_date: state.to_date,
        branchs: ids,
        company_id: state.company_id,
        per_page:state.per_page,
        page: 1
      })
      debugger
      commit('set_page', 1)
      commit('set_ls_product', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async pdf_product_im_ex({ state, commit },lang){
      var loader = Vue.$loading.show()
      let ids=null
      if(state.branchs.length>0){
        ids=state.branchs.map(function(item) {
          return item['id']
        }).join()
      }
      let res= await orderService.pdf_product_im_ex({"lang":lang,data:{company_id:state.company_id,
        branchs:ids,from_date:state.from_date,has_son:state.has_son,
        to_date: state.to_date}})
      loader.hide()
      return res
    },
    async excel_product_im_ex({ state, commit },lang){
      var loader = Vue.$loading.show()
      let ids=null
      if(state.branchs.length>0){
        ids=state.branchs.map(function(item) {
          return item['id']
        }).join()
      }
      let res= await orderService.excel_product_im_ex({company_id:state.company_id,
        branchs:ids,from_date:state.from_date,has_son:state.has_son,
        to_date: state.to_date})
      loader.hide()
      return res
    }
  },
  mutations: {
    set_ls_product (state, { items, pages,per_page }) {
      state.ls_product = items
      state.page_count = pages
      state.per_page=per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_from_date (state, date) {
      state.from_date = date
    },
    set_to_date (state, date) {
      state.to_date = date
    },
    set_branchs (state, ls) {
      state.branchs = ls
    },
    set_company_id (state, id) {
      state.company_id = id
    },
    set_has_son (state, flag) {
      state.has_son = flag
    }
  }
}
