import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'
import { Business } from '@/helpers/business.js'

export const importService = {
  get_ls_import,
  create,
  update,
  get_ls_import_ddl,
  delete_obj,
  recover_obj,
  bill_pdf,
  name_unique,
  code_unique
}

function name_unique(param){
  return Axios.get(`${apiUrl}/import/name-unique/${param.company_id}?name=${param.name}`,{headers: authHeader()})
}

function code_unique(param){
  return Axios.get(`${apiUrl}/import/code-unique/${param.company_id}?code=${param.code}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/import/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/import/recover/${id}`, {headers: authHeader()})
}

function get_ls_import(param) {
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/import/list`,requestOptions)
}

function get_ls_import_ddl(company_id){
  return Axios.get(`${apiUrl}/import/ddl/${company_id}`, {headers: authHeader()})
}

function create(item) {
  return Axios.post(`${apiUrl}/import/create`,item,{headers:authHeader()})
}

function update(item) {
  return Axios.put(`${apiUrl}/import/update/${item.id}`,item,{headers:authHeader()})
}

function bill_pdf(param) {
  const requestOptions = {
    method: 'GET',
    responseType: 'arraybuffer',
    headers: authHeader()
  }
  if(param.business==Business.cafe){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.dentist){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.karaoke){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.food){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.pawn){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.football){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.tole){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.hotel){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.hostel){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.bida){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.water){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.spa){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.oto){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.pharmacy){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.vlxd){
    return Axios.get(`${apiUrl}/import/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }
}

