import Axios from 'axios';
import apiUrl from '../utils/api';
import { authHeader } from '../helpers';

export const chamcongService = {
    get_ls_chamcong,
    create,
    update,
    phone_unique,
    ma_unique,
    delete_obj,
    recover_obj
};

function delete_obj(id) {
    return Axios.delete(`${apiUrl}/finger/delete?id=${id}`, {headers: authHeader()})
}
function recover_obj(id) {
    return Axios.delete(`${apiUrl}/finger/recover?id=${id}`, {headers: authHeader()})
}

function phone_unique(phone){
    return Axios.get(`${apiUrl}/finger/phone-unique?phone=${phone}`,{headers: authHeader()})
}

function ma_unique(ma){
    return Axios.get(`${apiUrl}/finger/ma-unique?ma=${ma}`,{headers: authHeader()})
}

function get_ls_chamcong(param) {
    const requestOptions = {
        headers: authHeader(),
        params:param
    }

    return Axios.get(`${apiUrl}/finger/list`,requestOptions)
}

function create(cc) {
  return Axios.post(`${apiUrl}/finger/create`,cc,{headers:authHeader()})
}

function update(cc) {
  return Axios.put(`${apiUrl}/finger/update/${cc.id}`,cc,{headers:authHeader()})
}
