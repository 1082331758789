import { invoiceService,supplyService,unitService } from '../services'

const http_get_ls_invoice = async (params) => {
  const { data } = await invoiceService.get_ls_invoice(params)
  return data
}

export const st_invoice = {
  namespaced: true,
  state: {
    ls_invoice: [],
    page: 1,
    page_count: 0,
    filter: null,
    ls_product_ddl:[],
    ls_unit_ddl:[],
    ls_supply_ddl:[],
    per_page:10,
    type:1
  },
  getters:{
    ls_invoice: state => state.ls_invoice,
    ls_product_ddl: state => state.ls_product_ddl,
    ls_unit_ddl: state => state.ls_unit_ddl,
    ls_supply_ddl: state => state.ls_supply_ddl,
    page: state => state.page,
    page_count: state => state.page_count,
    filter: state => state.filter,
    per_page:state=>state.per_page,
    type:state=>state.type
  },
  actions: {
    async ma_unique({commit},ma){
      return await invoiceService.ma_unique(ma)
    },
    async create({commit},invoice){
      return await invoiceService.create(invoice)
    },

    async update({commit},invoice){
      return await invoiceService.update(invoice)
    },

    async get_ls_invoice ({ state, commit },type) {
      const data = await http_get_ls_invoice({
        lang: state.lang,
        per_page:state.per_page,
        page: state.page,
        text: state.filter,
        type: type
      })
      commit('set_ls_invoice', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    get_ls_unit_ddl({ commit},param){
      debugger
      unitService.get_ls_unit_ddl(param)
      .then(unit => commit('set_ls_unit_ddl', unit))
    },

    async get_ls_supply_ddl ({ state, commit }) {
      supplyService.get_ls_supply_ddl()
        .then(
            ct => commit('set_ls_supply_ddl', ct)
        )
    },

    async change_page ({ state, commit }, param) {
      debugger
      const data = await http_get_ls_invoice({
        lang: state.lang,
        per_page:state.per_page,
        page: param.page,
        text: state.filter,
        type: param.type
      })
      commit('set_page', param.page)
      commit('set_ls_invoice', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit },type) {
      debugger
      const data = await http_get_ls_invoice({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter,
        type: type
      })
      commit('set_page', 1)
      commit('set_ls_invoice', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async delete_obj({commit},id){
      return await invoiceService.delete_obj(id)
    },

    async recover_obj({commit},id){
      let co= await invoiceService.recover_obj(id)
      return co
    }
  },
  mutations: {
    set_ls_invoice (state, { items, pages,per_page }) {
      state.ls_invoice = items
      state.page_count = pages
      state.per_page=per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_type (state, type) {
      state.type = type
    },
    set_filter (state, filter) {
      state.filter = filter
    },
    set_ls_product_ddl(state, items) {
      debugger
      state.ls_product_ddl = items.data
    },
    set_ls_unit_ddl(state, items) {
      state.ls_unit_ddl = items.data
    },
    set_ls_supply_ddl(state, items) {
      state.ls_supply_ddl = items.data
    }
  }
}
