import { staffService } from '../services'

const http_get_ls_staff = async (params) => {
  const { data } = await staffService.get_ls_staff(params)
  return data
}

export const st_staff = {
  namespaced: true,
  state: {
    ls_staff: [],
    ls_staff_ddl:[],
    page: 1,
    page_count: 0,
    filter: null,
    per_page:10,
    lang:'en',
    type:0
  },
  getters:{
    ls_staff: state => state.ls_staff,
    page: state => state.page,
    page_count: state => state.page_count,
    filter: state => state.filter,
    per_page:state=>state.per_page,
    ls_staff_ddl:state=>state.ls_staff_ddl,
    type:state=>state.type
  },
  actions: {
    get_ls_staff_ddl ({ state, commit },company_id) {
      staffService.get_ls_staff_ddl(company_id)
      .then(
        company => commit('set_ls_staff_ddl', company)
      )
    },

    async delete_obj({commit},id){
      return await staffService.delete_obj(id)
    },

    async create({commit},staff){
      return await staffService.create(staff)
    },

    async update({commit},staff){
      return await staffService.update(staff)
    },

    async get_ls_staff ({ state, commit }) {
      const data = await http_get_ls_staff({
        lang:state.lang,
        per_page:state.per_page,
        page: state.page,
        filter: state.filter
      })

      commit('set_ls_staff', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page ({ state, commit }, page) {
      const data = await http_get_ls_staff({
        lang: state.lang,
        per_page:state.per_page,
        page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_staff', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }) {
      debugger
      const data = await http_get_ls_staff({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_staff', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    }
  },
  mutations: {
    set_ls_staff (state, { items, pages,per_page }) {
      state.ls_staff = items
      state.page_count = pages
      state.per_page = per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    },
    set_ls_staff_ddl(state, items) {
      state.ls_staff_ddl = items.data
    },
    set_type (state, val) {
      state.type = val
    }
  }
}
