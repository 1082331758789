const axios = require('axios')
import main from './main.js'
// axios.interceptors.request.use(
//   success => {
//     return success
//   },
//   error => {
//     main.$toastr.error($t(error.response.data.message), main.$t('message.error'), {})
//     return Promise.reject(error)
//   }
// )

// axios.interceptors.response.use(
//   success => {
//     return success
//   },
//   error => {
//     debugger
//     if (error.response.status === 401) {
//       main.$toastr.info("Phiên làm việc đã hết, vui lòng đăng nhập lại", "Thông báo!")
//       main.$store.dispatch(`st_authentication/do_logout`)
//       window.location.href = '/'
//     }

//     // if (error.response.status === 404) {
//     //   return Promise.reject(error)
//     //   // main.$toastr.error("Không tìm thấy đường dẫn. Vui lòng thử lại", "Thông báo!")
//     //   // window.location.href = '/'
//     // }

//     return Promise.reject(error)
//   }
// )

export default axios
