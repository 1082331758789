export const Role = {
  Super_Admin: 'super_admin',
  Admin: 'admin',
  Sub_Admin: 'sub_admin',
  User: 'user',

  Nhan_Vien_Ban_Hang: 'nhan_vien_ban_hang',
  Nhan_Vien_Kinh_Doanh: 'nhan_vien_kinh_doanh',
  Quan_Ly_Kho: 'quan_ly_kho',
  Nhan_Vien_Cham_Soc_Khach_Hang: 'nhan_vien_cham_soc_khach_hang',
  Ky_Thuat_Vien: 'ky_thuat_vien'
}
