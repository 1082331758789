export const Business = {
  cafe: 'cafe',
  vlxd: 'vlxd',
  dentist: 'dentist',
  pharmacy: 'pharmacy',
  karaoke: 'karaoke',
  food: 'food',
  pawn: 'pawn',
  football: 'football',
  tole: 'tole',
  hotel: 'hotel',
  water: 'water',
  bida: 'bida',
  spa: 'spa',
  oto: 'oto',
  hostel: 'hostel',
  gym: 'gym',
  system: 'system'
}
