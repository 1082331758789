import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const unitService = {
    get_ls_unit,
    get_by_id,
    create,
    update,
    delete_obj,
    recover_obj,
    name_unique,
    get_ls_unit_ddl
}

function name_unique(param){
  debugger
  return Axios.get(`${apiUrl}/unit/name-unique/${param.company_id}?business=${param.business}&name=${param.name}&lang=${param.lang}`,{headers: authHeader()})
}

function get_ls_unit_ddl(param){
  debugger
  return Axios.get(`${apiUrl}/unit/ddl/${param.company_id}?business=${param.business}&lang=${param.lang}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/unit/delete/${id}`,{headers: authHeader()})
}

function recover_obj(id) {
  return Axios.delete(`${apiUrl}/unit/recover/${id}`,{headers: authHeader()})
}

function get_ls_unit(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/unit/list`,requestOptions)
}

function get_by_id(id) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return Axios.get(`${apiUrl}/unit/one/${id}`, requestOptions)
}

function create(unit) {
  return Axios.post(`${apiUrl}/unit/create`,unit,{headers:authHeader()})
}

function update(unit) {
  return Axios.put(`${apiUrl}/unit/update/${unit.data.id}`,unit,{headers:authHeader()})
}
