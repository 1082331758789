import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const treatmentService = {
  get_ls_treatment,
  get_by_id,
  create,
  update,
  delete_obj,
  recover_obj,
  name_unique,
  phieu_kham_benh_pdf,
  release_object
}

function release_object(param) {
  debugger
  return Axios.put(`${apiUrl}/treatment/release/${param.id}`,param,{headers:authHeader()})
}

function phieu_kham_benh_pdf(param) {
  const requestOptions = {
    method: 'GET',
    responseType: 'arraybuffer',
    headers: authHeader()
  }

  return Axios.get(`${apiUrl}/treatment/bill/${param.treatment}?lang=${param.lang}`,requestOptions)
}

function name_unique(param){
  debugger
  return Axios.get(`${apiUrl}/treatment/name-unique/${param.company_id}?business=${param.business}&name=${param.name}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/treatment/delete/${id}`,{headers: authHeader()})
}

function recover_obj(id) {
  return Axios.delete(`${apiUrl}/treatment/recover/${id}`,{headers: authHeader()})
}

function get_ls_treatment(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/treatment/list`,requestOptions)
}

function get_by_id(id) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  }
  return Axios.get(`${apiUrl}/treatment/one/${id}`, requestOptions)
}

function create(param) {
  return Axios.post(`${apiUrl}/treatment/create`,param,{headers:authHeader()})
}

function update(param) {
  return Axios.put(`${apiUrl}/treatment/update/${param.treatment.id}`,param,{headers:authHeader()})
}
