import { treatmentService } from '../services'
import { authHeader } from '../helpers'
import Vue from 'vue'

const http_get_ls_treatment = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  const { data } = await treatmentService.get_ls_treatment(params)
  return data
}

export const st_treatment = {
  namespaced: true,
  state: {
    ls_treatment: [],
    page_treatment: 1,
    page_count_treatment: 0,
    filter_treatment: null,
    per_page_treatment:10,
    lang:'en'
  },
  getters:{
    ls_treatment: state => state.ls_treatment,
    page_treatment: state => state.page_treatment,
    page_count_treatment: state => state.page_count_treatment,
    filter_treatment: state => state.filter_treatment,
    per_page_treatment:state=>state.per_page_treatment
  },
  actions: {
    async delete_obj({commit},id){
      return await treatmentService.delete_obj(id)
    },

    async recover_obj({commit},id){
      return await treatmentService.recover_obj(id)
    },

    async phone_unique({ rootState, commit },phone){
      return await treatmentService.phone_unique(phone)
    },
    async create({commit},param){
      var loader = Vue.$loading.show()
      let create= await treatmentService.create(param)
      loader.hide()
      return create
    },

    async update({commit},param){
      var loader = Vue.$loading.show()
      let update= await treatmentService.update(param)
      loader.hide()
      return update
    },

    async get_ls_treatment ({ state, commit }) {
      const data = await http_get_ls_treatment({
        lang:state.lang,
        per_page:state.per_page_treatment,
        page: state.page,
        filter: state.filter_treatment
      })

      commit('set_ls_treatment', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page_treatment ({ state, commit }, page) {
      const data = await http_get_ls_treatment({
        lang: state.lang,
        per_page:state.per_page_treatment,
        page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_treatment', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter_treatment ({ state, commit }) {
      debugger
      const data = await http_get_ls_treatment({
        lang: state.lang,
        per_page:state.per_page_treatment,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_treatment', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async phieu_kham_benh_pdf({ commit },param){
      var loader = Vue.$loading.show()
      let pdf= await treatmentService.phieu_kham_benh_pdf(param)
      loader.hide()
      return pdf
    },
    
    async release_object({commit},param){
      var loader = Vue.$loading.show()
      let res= await treatmentService.release_object(param)
      loader.hide()
      return res
    }
  },
  mutations: {
    set_ls_treatment (state, { items, pages,per_page }) {
      state.ls_treatment = items
      state.page_count_treatment = pages
      state.per_page_treatment = per_page
    },
    set_per_page (state, page) {
      state.per_page_treatment = page
    },
    set_page (state, page) {
      state.page_treatment = page
    },
    set_filter (state, filter) {
      state.filter_treatment = filter
    }
  }
}
