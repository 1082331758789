import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const invoiceService = {
  get_ls_invoice,
  create,
  update,
  get_ls_invoice_ddl,
  delete_obj,
  recover_obj
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/invoice/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/invoice/recover/${id}`, {headers: authHeader()})
}

function get_ls_invoice(param) {
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/invoice/list`,requestOptions)
}

function get_ls_invoice_ddl(){
  return Axios.get(`${apiUrl}/invoice/ddl`, {headers: authHeader()})
}

function create(sp) {
  return Axios.post(`${apiUrl}/invoice/create`,sp,{headers:authHeader()})
}

function update(sp) {
  return Axios.put(`${apiUrl}/invoice/update/${sp.id}`,sp,{headers:authHeader()})
}
