import Vue from 'vue'
import Router from 'vue-router'
import { Role } from '@/helpers/role.js'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// view containers
const Home = () => import('@/views/container/home/Index')

const HomeNew = () => import('@/views/ui/HomeView')
const Dichvu = () => import('@/views/container/dichvu/Index')
const Cauhoi = () => import('@/views/container/cauhoi/Index')
const Giaiphap = () => import('@/views/container/giaiphap/Index')
const Contact = () => import('@/views/container/lienhe/Index')
const Gioithieu = () => import('@/views/container/gioithieu/Index')
const Product = () => import('@/views/container/product/Index')
const Hotro = () => import('@/views/container/hotro/Index')
const ShareBranch = () => import('@/views/share/Branch')

// Views
const RegisterOk = () => import('@/views/pages/RegisterOk')
const ConfirmEmail = () => import('@/views/pages/ConfirmEmail')
const ShareType = () => import('@/views/share/Type')
// cafe
const CafeOrder = () => import('@/views/cafe/Order')
const KaraokeOrder = () => import('@/views/karaoke/Order')
const ToleOrder = () => import('@/views/tole/Order')
const BidaOrder = () => import('@/views/bida/Order')
// pawn
const ShareClient = () => import('@/views/share/Client')
const DentistTreatment = () => import('@/views/dentist/Treatment')
const PawnOrder = () => import('@/views/pawn/Order')
const PharmacyOrder = () => import('@/views/pharmacy/Order')
const OtoOrder = () => import('@/views/oto/Order')
const WaterOrder = () => import('@/views/water/Order')
const SpaOrder = () => import('@/views/spa/Order')
const HostelOrder = () => import('@/views/hostel/Order')
// dentist
const DentistBacsy = () => import('@/views/dentist/Doctor')
// food
const FoodOrder = () => import('@/views/food/Order')
// hotel
const ShareObject = () => import('@/views/share/Object')
const ShareStaff = () => import('@/views/share/Staff')
// karaoke
const ShareProduct = () => import('@/views/share/Product')
const ShareService = () => import('@/views/share/Service')
// pharmacy
const ShareUnit = () => import('@/views/share/Unit')
const ShareOther = () => import('@/views/share/Other')
// tole
const TolePhieunhap = () => import('@/views/tole/Phieunhap')
// vlxd
const ShareSupply = () => import('@/views/share/Supply')
const VlxdCongtrinh = () => import('@/views/share/Object')
const VlxdPhieugiao = () => import('@/views/vlxd/Phieugiao')
const VlxdPhieunhap = () => import('@/views/vlxd/Phieunhap')
const Order_vlxd = () => import('@/views/vlxd/Order')
const OrderDelivery = () => import('@/views/vlxd/Delivery')
const OrderReturn = () => import('@/views/share/Return')
const ShareDelivery = () => import('@/views/share/DeliveryWaterTole')
const ShareCar = () => import('@/views/share/Car')
// bida
const ShareImportProduct = () => import('@/views/share/ImportProduct')
// bóng đá
const Share_Report_Profit = () => import('@/views/share/Report_Profit')
//
const Share_Report_Company_activity = () => import('@/views/share/Report_Company_Activity')
const Share_Report_ImEx = () => import('@/views/share/Report_ImEx')
const Share_Report_Client_Debt = () => import('@/views/share/Report_Client_Debt')
const Share_Report_Supply_Debt = () => import('@/views/share/Report_Supply_Debt')
const Share_Report_User_Working = () => import('@/views/share/Report_User_Working')
const FootballOrder = () => import('@/views/football/Order')
const OrderHotelKaraCafeBidaFoodFootballSpa = () => import('@/views/share/OrderHotelKaraCafeBidaFoodFootballSpa')
const OrderHotel = () => import('@/views/hotel/Order')

const CongtyAdmin = () => import('@/views/share/Company')
const ProfileAdmin = () => import('@/views/share/Profile')
const SettingAdmin = () => import('@/views/share/Setting')

const ShareUser = () => import('@/views/share/User')

const CafeDetail = () => import('@/views/container/detail/Cafe')
const KaraokeDetail = () => import('@/views/container/detail/Karaoke')
const BidaDetail = () => import('@/views/container/detail/Bida')
const PawnDetail = () => import('@/views/container/detail/Pawn')
const DentistDetail = () => import('@/views/container/detail/Dentist')
const FoodDetail = () => import('@/views/container/detail/Food')
const FootballDetail = () => import('@/views/container/detail/Football')
const HotelDetail = () => import('@/views/container/detail/Hotel')
const OtoDetail = () => import('@/views/container/detail/Oto')
const PharmacyDetail = () => import('@/views/container/detail/Pharmacy')
const SpaDetail = () => import('@/views/container/detail/Spa')
const ToleDetail = () => import('@/views/container/detail/Tole')
const VlxdDetail = () => import('@/views/container/detail/Vlxd')
const WaterDetail = () => import('@/views/container/detail/Water')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Switches = () => import('@/views/base/Switches')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tables = () => import('@/views/tables/Tables')
const AdvancedTables = () => import('@/views/tables/AdvancedTables')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')
const LoadingButtons = () => import('@/views/buttons/LoadingButtons')

// Views - Editors
const TextEditors = () => import('@/views/editors/TextEditors')
const CodeEditors = () => import('@/views/editors/CodeEditors')

// Views - Forms
const BasicForms = () => import('@/views/forms/Forms')
const AdvancedForms = () => import('@/views/forms/AdvancedForms')
const ValidationForms = () => import('@/views/forms/ValidationForms')
const MultiSelect = () => import('@/views/forms/MultiSelect')

// Views GoogleMaps
const GoogleMaps = () => import('@/views/GoogleMaps')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')
const Toaster = () => import('@/views/notifications/Toaster')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Signup = () => import('@/views/uiregistration/SignupBox')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

// Plugins
const Draggable = () => import('@/views/plugins/Draggable')
const Calendar = () => import('@/views/plugins/Calendar')
const Spinners = () => import('@/views/plugins/Spinners')

// Apps -> Invoice
const Invoice = () => import('@/views/apps/invoicing/Invoice')

// Apps -> Email
const EmailApp = () => import('@/views/apps/email/EmailApp')
const Compose = () => import('@/views/apps/email/Compose')
const Inbox = () => import('@/views/apps/email/Inbox')
const Message = () => import('@/views/apps/email/Message')

Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: '/login',meta: { label: 'Đăng nhập'}, component: Login },
    { path: '/signup',meta: { label: 'Đăng ký'}, component: Signup },
    { path: '/confirm-email', name: 'confirm-email', component: ConfirmEmail},
    { path: '/dang-ky-ok', name: 'dang-ky-ok', component: RegisterOk},
    { path: "/not-found", component: Page404 },
    { path: "*", component: Page404 },
    {
      path: '/',
      redirect: '/home',
      name: 'Home',
      component: HomeNew,
      children:[
        {
          path: '/product/cafe',
          meta: { label: 'Nội dung chi tiết'},
          component: CafeDetail
        },
        {
          path: '/product/bida',
          meta: { label: 'Nội dung chi tiết'},
          component: BidaDetail
        },
        {
          path: '/product/pawn',
          meta: { label: 'Nội dung chi tiết'},
          component: PawnDetail
        },
        {
          path: '/product/dentist',
          meta: { label: 'Nội dung chi tiết'},
          component: DentistDetail
        },
        {
          path: '/product/food',
          meta: { label: 'Nội dung chi tiết'},
          component: FoodDetail
        },
        {
          path: '/product/football',
          meta: { label: 'Nội dung chi tiết'},
          component: FootballDetail
        },
        {
          path: '/product/hotel',
          meta: { label: 'Nội dung chi tiết'},
          component: HotelDetail
        },
        {
          path: '/product/karaoke',
          meta: { label: 'Nội dung chi tiết'},
          component: KaraokeDetail
        },
        {
          path: '/product/oto',
          meta: { label: 'Nội dung chi tiết'},
          component: OtoDetail
        },
        {
          path: '/product/pharmacy',
          meta: { label: 'Nội dung chi tiết'},
          component: PharmacyDetail
        },
        {
          path: '/product/spa',
          meta: { label: 'Nội dung chi tiết'},
          component: SpaDetail
        },
        {
          path: '/product/tole',
          meta: { label: 'Nội dung chi tiết'},
          component: ToleDetail
        },
        {
          path: '/product/vlxd',
          meta: { label: 'Nội dung chi tiết'},
          component: VlxdDetail
        },
        {
          path: '/product/water',
          meta: { label: 'Nội dung chi tiết'},
          component: WaterDetail
        },
        
        {
          path: '/question',
          meta: { label: 'Danh sách hỏi đáp'},
          component: Cauhoi
        },
        {
          path: '/product',
          meta: { label: 'Danh sách sản phẩm'},
          component: Product
        },
        {
          path: '/service',
          meta: { label: 'Danh sách dịch vụ'},
          component: Dichvu
        },
        {
          path: '/giai-phap',
          meta: { label: 'Tư vấn giải pháp doanh nghiệp'},
          component: Giaiphap
        },
        {
          path: '/home',
          meta: { label: 'Trang chủ'},
          component: Home
        },
        {
          path: '/lien-he',
          meta: { label: 'Thông tin liên hệ'},
          component: Contact
        },
        {
          path: '/ho-tro',
          meta: { label: 'Thông tin liên hệ'},
          component: Hotro
        },
        {
          path: '/share',
          meta: { label: 'Thông tin mới tại hansan'},
          component: Gioithieu
        }
      ]
    },

    {
      path: '/',
      redirect: '/:business',
      component: TheContainer,
      children: [
        {
          path: ':business',
          component: Dashboard
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },


        {
          path: 'water',
          redirect: '/water/ls-order',
          name: 'Water',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/water/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: Dashboard
            },
            {
              path: '/water/ls-order',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'water'},
              component: WaterOrder
            },
            {
              path: '/water/ls-delivery',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Nhan_Vien_Kinh_Doanh],business:'water'},
              component: ShareDelivery
            },
            {
              path: '/water/ls-return',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Nhan_Vien_Kinh_Doanh],business:'water'},
              component: OrderReturn
            },
            {
              path: '/water/ls-branch',
              name: 'water-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'water'},
              component: ShareBranch
            },
            {
              path: '/water/ls-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'water'},
              component: ShareProduct
            },
            {
              path: '/water/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'water'},
              component: ShareImportProduct
            },
            {
              path: '/water/ls-unit',
              name: 'water-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Quan_Ly_Kho,Role.Branch_Admin],business:'water'},
              component: ShareUnit
            },
            {
              path: '/water/ls-other-outcome',
              name: 'water-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: ShareOther
            },
            {
              path: '/water/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: ShareClient
            },
            {
              path: '/water/ls-staff',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: ShareStaff
            },
            {
              path: '/water/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: ShareUser
            },
            {
              path: '/water/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Nhan_Vien_Kinh_Doanh],business:'water'},
              component: ShareSupply
            },
            {
              path: '/water/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: Share_Report_User_Working
            },
            {
              path: '/water/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: Share_Report_Profit
            },
            {
              path: '/water/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: Share_Report_Company_activity
            },
            {
              path: '/water/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: Share_Report_ImEx
            },
            {
              path: '/water/rpt-client-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: Share_Report_Client_Debt
            },
            {
              path: '/water/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'water'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
        {
          path: 'cafe',
          redirect: '/cafe/ls-order',
          name: 'Cafe',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/cafe/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'cafe'},
              component: Dashboard
            },
            {
              path: '/cafe/ls-order',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'cafe'},
              component: CafeOrder
            },
            {
              path: '/cafe/ls-branch',
              name: 'cafe-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin],business:'cafe'},
              component: ShareBranch
            },
            {
              path: '/cafe/ls-table',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'cafe'},
              component: ShareObject
            },
            {
              path: '/cafe/ls-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'cafe'},
              component: ShareProduct
            },
            {
              path: '/cafe/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'cafe'},
              component: ShareImportProduct
            },
            {
              path: '/cafe/ls-unit',
              name: 'cafe-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'cafe'},
              component: ShareUnit
            },
            {
              path: '/cafe/ls-other-outcome',
              name: 'cafe-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'cafe'},
              component: ShareOther
            },
            {
              path: '/cafe/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'cafe'},
              component: ShareUser
            },
            {
              path: '/cafe/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'cafe'},
              component: ShareSupply
            },
            {
              path: '/cafe/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'cafe'},
              component: Share_Report_User_Working
            },
            {
              path: '/cafe/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'cafe'},
              component: Share_Report_Profit
            },
            {
              path: '/cafe/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'cafe'},
              component: Share_Report_Company_activity
            },
            {
              path: '/cafe/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'cafe'},
              component: Share_Report_ImEx
            },
            {
              path: '/cafe/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'cafe'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
        {
          path: 'karaoke',
          redirect: '/karaoke/ls-order',
          name: 'Karaoke',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/karaoke/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'karaoke'},
              component: Dashboard
            },
            {
              path: '/karaoke/ls-order',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'karaoke'},
              component: KaraokeOrder
            },
            {
              path: '/karaoke/ls-branch',
              name: 'karaoke-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'karaoke'},
              component: ShareBranch
            },
            {
              path: '/karaoke/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'karaoke'},
              component: ShareUser
            },
            {
              path: '/karaoke/ls-type',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'karaoke'},
              component: ShareType
            },
            {
              path: '/karaoke/ls-room',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'karaoke'},
              component: ShareObject
            },
            {
              path: '/karaoke/ls-unit',
              name: 'karaoke-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'karaoke'},
              component: ShareUnit
            },
            {
              path: '/karaoke/ls-other-outcome',
              name: 'karaoke-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'karaoke'},
              component: ShareOther
            },
            {
              path: '/karaoke/ls-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'karaoke'},
              component: ShareProduct
            },
            {
              path: '/karaoke/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'karaoke'},
              component: ShareImportProduct
            },
            {
              path: '/karaoke/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'karaoke'},
              component: ShareSupply
            },
            {
              path: '/karaoke/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'karaoke'},
              component: Share_Report_User_Working
            },
            {
              path: '/karaoke/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'karaoke'},
              component: Share_Report_Profit
            },
            {
              path: '/karaoke/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'karaoke'},
              component: Share_Report_Company_activity
            },
            {
              path: '/karaoke/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'karaoke'},
              component: Share_Report_ImEx
            },
            {
              path: '/karaoke/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'karaoke'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
        {
          path: 'pawn',
          redirect: '/pawn/ls-order',
          name: 'Pawn',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/pawn/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'pawn'},
              component: Dashboard
            },
            {
              path: '/pawn/ls-branch',
              name: 'pawn-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'pawn'},
              component: ShareBranch
            },
            {
              path: '/pawn/ls-product',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin],business:'pawn'},
              component: ShareProduct
            },
            {
              path: '/pawn/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'pawn'},
              component: ShareImportProduct
            },
            {
              path: '/pawn/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pawn'},
              component: ShareClient
            },
            {
              path: '/pawn/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pawn'},
              component: ShareSupply
            },
            {
              path: '/pawn/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'pawn'},
              component: ShareUser
            },
            {
              path: '/pawn/ls-unit',
              name: 'pawn-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.User,Role.Branch_Admin,Role.Sub_Admin],business:'pawn'},
              component: ShareUnit
            },
            {
              path: '/pawn/ls-other-outcome',
              name: 'pawn-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'pawn'},
              component: ShareOther
            },
            {
              path: '/pawn/ls-order',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.User,Role.Sub_Admin],business:'pawn'},
              component: PawnOrder
            },
            {
              path: '/pawn/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pawn'},
              component: Share_Report_User_Working
            },
            {
              path: '/pawn/rpt-client-refun',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pawn'},
              component: Share_Report_User_Working
            },
            {
              path: '/pawn/rpt-client-expire',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pawn'},
              component: Share_Report_User_Working
            },
            {
              path: '/pawn/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pawn'},
              component: Share_Report_Profit
            },
            {
              path: '/pawn/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pawm'},
              component: Share_Report_Company_activity
            },
            {
              path: '/pawn/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pawn'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
        {
          path: ':business',
          redirect: '/:business/ls-treatment',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/:business/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'dentist'},
              component: Dashboard
            },
            {
              path: '/:business/ls-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'dentist'},
              component: ShareBranch
            },
            {
              path: '/:business/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: ShareClient
            },
            {
              path: '/:business/ls-treatment',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: DentistTreatment
            },
            {
              path: '/:business/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: ShareUser
            },
            {
              path: '/:business/ls-doctor',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'dentist'},
              component: DentistBacsy
            },
    
            {
              path: '/:business/ls-product',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: ShareProduct
            },
            {
              path: '/:business/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: ShareImportProduct
            },
            {
              path: '/:business/ls-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.User,Role.Branch_Admin,Role.Sub_Admin],business:'dentist'},
              component: ShareUnit
            },
            {
              path: '/:business/ls-other-outcome',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'dentist'},
              component: ShareOther
            },
            {
              path: '/:business/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'dentist'},
              component: ShareSupply
            },
            {
              path: '/:business/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: Share_Report_User_Working
            },
            {
              path: '/:business/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: Share_Report_Profit
            },
            {
              path: '/:business/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: Share_Report_Company_activity
            },
            {
              path: '/:business/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: Share_Report_ImEx
            },
            {
              path: '/:business/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: Share_Report_Supply_Debt
            },
            {
              path: '/:business/profile',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: ProfileAdmin
            },
            {
              path: '/:business/setting',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'dentist'},
              component: SettingAdmin
            },
            {
              path: 'coreui-icons',
              name: 'CoreUI Icons',
              component: CoreUIIcons
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            }
          ]
        },
        {
          path: 'food',
          redirect: '/food/ls-order',
          name: 'Food',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/food/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'food'},
              component: Dashboard
            },
            {
              path: '/food/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'food'},
              component: ShareUser
            },
            {
              path: '/food/ls-branch',
              name: 'food-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin],business:'food'},
              component: ShareBranch
            },
            {
              path: '/food/ls-order',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Sub_Admin,Role.Branch_Admin,Role.User],business:'food'},
              component: FoodOrder
            },
            {
              path: '/food/ls-unit',
              name: 'food-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'food'},
              component: ShareUnit
            },
            {
              path: '/food/ls-other-outcome',
              name: 'food-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'food'},
              component: ShareOther
            },
            {
              path: '/food/ls-table',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'food'},
              component: ShareObject
            },
            {
              path: '/food/ls-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'food'},
              component: ShareProduct
            },
            {
              path: '/food/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'food'},
              component: ShareImportProduct
            },
            {
              path: '/food/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'food'},
              component: ShareSupply
            },
            {
              path: '/food/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'food'},
              component: ShareClient
            },
            {
              path: '/food/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'food'},
              component: Share_Report_User_Working
            },
            {
              path: '/food/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'food'},
              component: Share_Report_Profit
            },
            {
              path: '/food/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'food'},
              component: Share_Report_Company_activity
            },
            {
              path: '/food/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'food'},
              component: Share_Report_ImEx
            },
            {
              path: '/food/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'food'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
        {
          path: 'football',
          redirect: '/football/ls-order',
          name: 'Football',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/football/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'football'},
              component: Dashboard
            },
            {
              path: '/football/ls-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'football'},
              component: ShareProduct
            },
            {
              path: '/football/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'football'},
              component: ShareClient
            },
            {
              path: '/football/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'football'},
              component: ShareImportProduct
            },
            {
              path: '/football/ls-branch',
              name: 'football-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'football'},
              component: ShareBranch
            },
            {
              path: '/football/ls-order',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin],business:'football'},
              component: FootballOrder
            },
            {
              path: '/football/ls-unit',
              name: 'football-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'football'},
              component: ShareUnit
            },
            {
              path: '/football/ls-other-outcome',
              name: 'football-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'football'},
              component: ShareOther
            },
            {
              path: '/football/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'football'},
              component: ShareClient
            },
            {
              path: '/football/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'football'},
              component: ShareUser
            },
            {
              path: '/football/ls-san',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'football'},
              component: ShareObject
            },
            {
              path: '/football/ls-type',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'football'},
              component: ShareType
            },
            {
              path: '/football/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'football'},
              component: ShareSupply
            },
            {
              path: '/football/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'football'},
              component: Share_Report_User_Working
            },
            {
              path: '/football/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'football'},
              component: Share_Report_Profit
            },
            {
              path: '/football/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'football'},
              component: Share_Report_Company_activity
            },
            {
              path: '/football/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'football'},
              component: Share_Report_ImEx
            },
            {
              path: '/football/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'football'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
        {
          path: 'hotel',
          redirect: '/hotel/ls-order',
          name: 'Hotel',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/hotel/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'hotel'},
              component: Dashboard
            },
            {
              path: '/hotel/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'hotel'},
              component: ShareUser
            },
            {
              path: '/hotel/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hotel'},
              component: ShareClient
            },
            {
              path: '/hotel/ls-branch',
              name: 'hotel-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin],business:'hotel'},
              component: ShareBranch
            },
            {
              path: '/hotel/ls-order',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin,Role.User],business:'hotel'},
              component: OrderHotel
            },
            {
              path: '/hotel/ls-unit',
              name: 'hotel-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'hotel'},
              component: ShareUnit
            },
            {
              path: '/hotel/ls-other-outcome',
              name: 'hotel-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'hotel'},
              component: ShareOther
            },
            {
              path: '/hotel/ls-room',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin,Role.User],business:'hotel'},
              component: ShareObject
            },
            {
              path: '/hotel/ls-type',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin],business:'hotel'},
              component: ShareType
            },
            {
              path: '/hotel/ls-product',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin],business:'hotel'},
              component: ShareProduct
            },
            {
              path: '/hotel/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'hotel'},
              component: ShareImportProduct
            },
            {
              path: '/hotel/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'hotel'},
              component: ShareSupply
            },
            {
              path: '/hotel/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hotel'},
              component: Share_Report_User_Working
            },
            {
              path: '/hotel/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hotel'},
              component: Share_Report_Profit
            },
            {
              path: '/hotel/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hotel'},
              component: Share_Report_Company_activity
            },
            {
              path: '/hotel/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hotel'},
              component: Share_Report_ImEx
            },
            {
              path: '/hotel/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hotel'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
    
        {
          path: 'hostel',
          redirect: '/hostel/ls-order',
          name: 'hostel',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/hostel/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'hostel'},
              component: Dashboard
            },
            {
              path: '/hostel/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'hostel'},
              component: ShareUser
            },
            {
              path: '/hostel/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hostel'},
              component: ShareClient
            },
            {
              path: '/hostel/ls-branch',
              name: 'hostel-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin],business:'hostel'},
              component: ShareBranch
            },
            {
              path: '/hostel/ls-order',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin,Role.User],business:'hostel'},
              component: HostelOrder
            },
            {
              path: '/hostel/ls-unit',
              name: 'hostel-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'hostel'},
              component: ShareUnit
            },
            {
              path: '/hostel/ls-other-outcome',
              name: 'hostel-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'hostel'},
              component: ShareOther
            },
            {
              path: '/hostel/ls-room',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin,Role.User],business:'hostel'},
              component: ShareObject
            },
            {
              path: '/hostel/ls-type',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin],business:'hostel'},
              component: ShareType
            },
            {
              path: '/hostel/ls-product',
              meta: { requiresAuth:true, authorize: [Role.Sub_Admin,Role.Admin,Role.Branch_Admin],business:'hostel'},
              component: ShareProduct
            },
            {
              path: '/hostel/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'hostel'},
              component: ShareImportProduct
            },
            {
              path: '/hostel/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'hostel'},
              component: ShareSupply
            },
            {
              path: '/hostel/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hostel'},
              component: Share_Report_Profit
            },
            {
              path: '/hostel/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hostel'},
              component: Share_Report_Company_activity
            },
            {
              path: '/hostel/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hostel'},
              component: Share_Report_User_Working
            },
            {
              path: '/hostel/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hostel'},
              component: Share_Report_ImEx
            },
            {
              path: '/hostel/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'hostel'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
        {
          path: 'pharmacy',
          redirect: '/pharmacy/ls-order',
          name: 'Pharmacy',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/pharmacy/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'pharmacy'},
              component: Dashboard
            },
            {
              path: '/pharmacy/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pharmacy'},
              component: ShareUser
            },
            {
              path: '/pharmacy/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pharmacy'},
              component: ShareClient
            },
            {
              path: '/pharmacy/ls-branch',
              name: 'pharmacy-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'pharmacy'},
              component: ShareBranch
            },
            {
              path: '/pharmacy/ls-order',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Sub_Admin,Role.Admin,Role.Branch_Admin],business:'pharmacy'},
              component: PharmacyOrder
            },
            {
              path: '/pharmacy/ls-unit',
              name: 'pharmacy-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'pharmacy'},
              component: ShareUnit
            },
            {
              path: '/pharmacy/ls-other-outcome',
              name: 'pharmacy-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'pharmacy'},
              component: ShareOther
            },
            {
              path: '/pharmacy/ls-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'pharmacy'},
              component: ShareProduct
            },
            {
              path: '/pharmacy/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'pharmacy'},
              component: ShareImportProduct
            },
            {
              path: '/pharmacy/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'pharmacy'},
              component: ShareSupply
            },
            {
              path: '/pharmacy/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pharmacy'},
              component: Share_Report_User_Working
            },
            {
              path: '/pharmacy/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pharmacy'},
              component: Share_Report_Profit
            },
            {
              path: '/pharmacy/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pharmacy'},
              component: Share_Report_Company_activity
            },
            {
              path: '/pharmacy/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pharmacy'},
              component: Share_Report_ImEx
            },
            {
              path: '/pharmacy/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'pharmacy'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
        {
          path: 'tole',
          redirect: '/tole/ls-order',
          name: 'Tole',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/tole/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'tole'},
              component: Dashboard
            },
            {
              path: '/tole/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'tole'},
              component: ShareUser
            },
            {
              path: '/tole/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'tole'},
              component: ShareClient
            },
            {
              path: '/tole/ls-branch',
              name: 'tole-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin],business:'tole'},
              component: ShareBranch
            },
            {
              path: '/tole/ls-order',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'tole'},
              component: ToleOrder
            },
            {
              path: '/tole/ls-delivery',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'tole'},
              component: ShareDelivery
            },
            {
              path: '/tole/ls-return',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'tole'},
              component: OrderReturn
            },
            {
              path: '/tole/ls-nhap',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin,Role.User],business:'tole'},
              component: TolePhieunhap
            },
            {
              path: '/tole/ls-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'tole'},
              component: ShareProduct
            },
            {
              path: '/tole/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'tole'},
              component: ShareImportProduct
            },
            {
              path: '/tole/ls-unit',
              name: 'tole-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin,Role.User],business:'tole'},
              component: ShareUnit
            },
            {
              path: '/tole/ls-other-outcome',
              name: 'tole-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'tole'},
              component: ShareOther
            },
            {
              path: '/tole/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'tole'},
              component: ShareSupply
            },
            {
              path: '/tole/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'tole'},
              component: Share_Report_User_Working
            },
            {
              path: '/tole/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'tole'},
              component: Share_Report_Profit
            },
            {
              path: '/tole/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'tole'},
              component: Share_Report_Company_activity
            },
            {
              path: '/tole/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'tole'},
              component: Share_Report_ImEx
            },
            {
              path: '/tole/rpt-client-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'tole'},
              component: Share_Report_Client_Debt
            },
            {
              path: '/tole/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'tole'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
        {
          path: 'vlxd',
          redirect: '/vlxd/ls-order',
          name: 'Vlxd',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/vlxd/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: Dashboard
            },
            {
              path: '/vlxd/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: ShareUser
            },
            {
              path: '/vlxd/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: ShareClient
            },
            {
              path: '/vlxd/ls-staff',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: ShareStaff
            },
            {
              path: '/vlxd/ls-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: ShareUnit
            },
            {
              path: '/vlxd/ls-other-outcome',
              name: 'vlxd-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: ShareOther
            },
            {
              path: '/vlxd/ls-car',
              name: 'vlxd-car-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: ShareCar
            },
            {
              path: '/vlxd/ls-branch',
              name: 'vlxd-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'vlxd'},
              component: ShareBranch
            },
            {
              path: '/vlxd/ls-product',
              name: 'vlxd-product',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Quan_Ly_Kho],business:'vlxd'},
              component: ShareProduct
            },
            {
              path: '/vlxd/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.Quan_Ly_Kho,Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: ShareImportProduct
            },
            {
              path: '/vlxd/ls-supply',
              name: 'vlxd-nhacungcap',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: ShareSupply
            },
            {
              path: '/vlxd/ls-phieu-giao',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: VlxdPhieugiao
            },
            {
              path: '/vlxd/ls-phieu-nhap',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: VlxdPhieunhap
            },
            {
              path: '/vlxd/ls-project',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: VlxdCongtrinh
            },
            {
              path: '/vlxd/ls-order',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Nhan_Vien_Kinh_Doanh],business:'vlxd'},
              component: Order_vlxd
            },
            {
              path: '/vlxd/ls-delivery',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Nhan_Vien_Kinh_Doanh],business:'vlxd'},
              component: OrderDelivery
            },
            {
              path: '/vlxd/ls-return',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Nhan_Vien_Kinh_Doanh],business:'vlxd'},
              component: OrderReturn
            },
            {
              path: '/vlxd/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: Share_Report_User_Working
            },
            {
              path: '/vlxd/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: Share_Report_Profit
            },
            {
              path: '/vlxd/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: Share_Report_Company_activity
            },
            {
              path: '/vlxd/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: Share_Report_ImEx
            },
            {
              path: '/vlxd/rpt-client-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: Share_Report_Client_Debt
            },
            {
              path: '/vlxd/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'vlxd'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
        {
          path: 'bida',
          redirect: '/bida/ls-order',
          name: 'Bida',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/bida/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'bida'},
              component: Dashboard
            },
            {
              path: '/bida/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'bida'},
              component: ShareUser
            },
            {
              path: '/bida/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'bida'},
              component: ShareClient
            },
            {
              path: '/bida/ls-branch',
              name: 'bida-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin],business:'bida'},
              component: ShareBranch
            },
            {
              path: '/bida/ls-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'bida'},
              component: ShareProduct
            },
            {
              path: '/bida/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'bida'},
              component: ShareImportProduct
            },
            {
              path: '/bida/ls-table',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'bida'},
              component: ShareObject
            },
            {
              path: '/bida/ls-type',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'bida'},
              component: ShareType
            },
            {
              path: '/bida/ls-unit',
              name: 'bida-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.User,Role.Sub_Admin],business:'bida'},
              component: ShareUnit
            },
            {
              path: '/bida/ls-other-outcome',
              name: 'bida-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'bida'},
              component: ShareOther
            },
            {
              path: '/bida/ls-order',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'bida'},
              component: BidaOrder
            },
            {
              path: '/bida/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'bida'},
              component: ShareSupply
            },
            {
              path: '/bida/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'bida'},
              component: Share_Report_User_Working
            },
            {
              path: '/bida/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'bida'},
              component: Share_Report_Profit
            },
            {
              path: '/bida/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'bida'},
              component: Share_Report_Company_activity
            },
            {
              path: '/bida/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'bida'},
              component: Share_Report_ImEx
            },
            {
              path: '/bida/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'bida'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
    
        {
          path: 'spa',
          redirect: '/spa/ls-order',
          name: 'Spa',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/spa/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'spa'},
              component: Dashboard
            },
            {
              path: '/spa/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'spa'},
              component: ShareUser
            },
            {
              path: '/spa/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'spa'},
              component: ShareClient
            },
            {
              path: '/spa/ls-order',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'spa'},
              component: SpaOrder
            },
            {
              path: '/spa/ls-branch',
              name: 'spa-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin],business:'spa'},
              component: ShareBranch
            },
            {
              path: '/spa/ls-type',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'spa'},
              component: ShareType
            },
            {
              path: '/spa/ls-room',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'spa'},
              component: ShareObject
            },
            {
              path: '/spa/ls-service',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'spa'},
              component: ShareService
            },
            {
              path: '/spa/ls-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'spa'},
              component: ShareProduct
            },
    
            {
              path: '/spa/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'spa'},
              component: ShareImportProduct
            },
            {
              path: '/spa/ls-unit',
              name: 'spa-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.User,Role.Sub_Admin],business:'spa'},
              component: ShareUnit
            },
            {
              path: '/spa/ls-other-outcome',
              name: 'spa-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'spa'},
              component: ShareOther
            },
            {
              path: '/spa/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'spa'},
              component: ShareSupply
            },
            {
              path: '/spa/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'spa'},
              component: Share_Report_User_Working
            },
            {
              path: '/spa/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'spa'},
              component: Share_Report_Profit
            },
            {
              path: '/spa/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'spa'},
              component: Share_Report_Company_activity
            },
            {
              path: '/spa/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'spa'},
              component: Share_Report_ImEx
            },
            {
              path: '/spa/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'spa'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
    
        {
          path: 'oto',
          redirect: '/oto/ls-order',
          name: 'Oto',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/oto/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'oto'},
              component: Dashboard
            },
            {
              path: '/oto/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'oto'},
              component: ShareUser
            },
            {
              path: '/oto/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'oto'},
              component: ShareClient
            },
            {
              path: '/oto/ls-branch',
              name: 'oto-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'oto'},
              component: ShareBranch
            },
            {
              path: '/oto/ls-service',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'oto'},
              component: ShareService
            },
            {
              path: '/oto/ls-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'oto'},
              component: ShareProduct
            },
            {
              path: '/oto/ls-order',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'oto'},
              component: OtoOrder
            },
            {
              path: '/oto/ls-unit',
              name: 'oto-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Quan_Ly_Kho],business:'oto'},
              component: ShareUnit
            },
            {
              path: '/oto/ls-other-outcome',
              name: 'oto-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'oto'},
              component: ShareOther
            },
            {
              path: '/oto/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'oto'},
              component: ShareImportProduct
            },
            {
              path: '/oto/ls-supply',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Nhan_Vien_Kinh_Doanh],business:'oto'},
              component: ShareSupply
            },
            {
              path: '/oto/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'oto'},
              component: Share_Report_User_Working
            },
            {
              path: '/oto/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'oto'},
              component: Share_Report_Profit
            },
            {
              path: '/oto/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'oto'},
              component: Share_Report_Company_activity
            },
            {
              path: '/oto/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'oto'},
              component: Share_Report_ImEx
            },
            {
              path: '/oto/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'oto'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
    
        {
          path: 'gym',
          redirect: '/gym/ls-user',
          name: 'Gym',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/gym/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'gym'},
              component: Dashboard
            },
            {
              path: '/gym/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'gym'},
              component: ShareUser
            },
            {
              path: '/gym/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'gym'},
              component: ShareClient
            },
            {
              path: '/gym/ls-branch',
              name: 'gym-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'gym'},
              component: ShareBranch
            },
            {
              path: '/gym/ls-service',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin],business:'gym'},
              component: ShareUser
            },
            {
              path: '/gym/ls-Phong',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin],business:'gym'},
              component: ShareUser
            },
            {
              path: '/gym/ls-unit',
              name: 'gym-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'gym'},
              component: ShareUnit
            },
            {
              path: '/gym/ls-other-outcome',
              name: 'gym-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'gym'},
              component: ShareOther
            },
            {
              path: '/gym/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'gym'},
              component: Share_Report_User_Working
            },
            {
              path: '/gym/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'gym'},
              component: Share_Report_Profit
            },
            {
              path: '/gym/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'gym'},
              component: Share_Report_Company_activity
            },
            {
              path: '/gym/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'gym'},
              component: Share_Report_ImEx
            },
            {
              path: '/gym/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'gym'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
    
        {
          path: 'massage',
          redirect: '/massage/ls-user',
          name: 'Massage',
          component: {
            render (c) { return c('router-view') }
          },
          children:[
            {
              path: '/massage/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'massage'},
              component: Dashboard
            },
            {
              path: '/massage/ls-user',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'massage'},
              component: ShareUser
            },
            {
              path: '/massage/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'massage'},
              component: ShareClient
            },
            {
              path: '/massage/ls-branch',
              name: 'massage-branch',
              meta: { requiresAuth:true, authorize: [Role.Admin],business:'massage'},
              component: ShareBranch
            },
            {
              path: '/massage/ls-service',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin],business:'massage'},
              component: ShareUser
            },
            {
              path: '/massage/ls-Phong',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin],business:'massage'},
              component: ShareUser
            },
            {
              path: '/massage/ls-unit',
              name: 'massage-unit',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'massage'},
              component: ShareUnit
            },
            {
              path: '/massage/ls-other-outcome',
              name: 'massage-other',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Sub_Admin,Role.Branch_Admin],business:'massage'},
              component: ShareOther
            },
            {
              path: '/massage/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'massage'},
              component: Share_Report_User_Working
            },
            {
              path: '/massage/rpt-income-per-day',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'massage'},
              component: Share_Report_Profit
            },
            {
              path: '/massage/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'massage'},
              component: Share_Report_Company_activity
            },
            {
              path: '/massage/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'massage'},
              component: Share_Report_ImEx
            },
            {
              path: '/massage/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'massage'},
              component: Share_Report_Supply_Debt
            }
          ]
        },
    
        {
          path: 'system',
          name: 'System',
          redirect: '/system/ls-user',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/system/home',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin,Role.Sub_Admin],business:'system'},
              component: Dashboard
            },
            {
              path: '/system/ls-user',
              name: 'sys-user',
              meta: { requiresAuth: true,authorize: [Role.Super_Admin],business:'system'},
              component: ShareUser
            },
            {
              path: '/system/ls-client',
              meta: { requiresAuth:true, authorize: [Role.Super_Admin],business:'system'},
              component: ShareClient
            },
            {
              path: '/system/ls-order',
              name: 'sys-order',
              meta: { requiresAuth: true,authorize: [Role.Super_Admin],business:'system'},
              component: OrderHotelKaraCafeBidaFoodFootballSpa
            },
            {
              path: '/system/profile',
              name: 'sys-profile',
              meta: { requiresAuth: true,authorize: [Role.User,Role.Admin,Role.Sub_Admin,Role.Super_Admin,Role.Branch_Admin],business:'system'},
              component: ProfileAdmin,
            },
            {
              path: '/system/setting',
              name: 'sys-setting',
              meta: { requiresAuth: true,authorize: [Role.Admin,Role.Sub_Admin,Role.Super_Admin,Role.Branch_Admin],business:'system'},
              component: SettingAdmin,
            },
            {
              path: '/system/ls-cong-ty',
              name: 'sys-company',
              meta: { requiresAuth: true,authorize: [Role.Super_Admin],business:'system'},
              component: CongtyAdmin
            },
            {
              path: '/system/ls-unit',
              name: 'sys-unit',
              meta: { requiresAuth:true, authorize: [Role.Super_Admin],business:'system'},
              component: ShareUnit
            },
            {
              path: '/system/ls-im-product',
              meta: { requiresAuth:true, authorize: [Role.User,Role.Admin,Role.Branch_Admin],business:'system'},
              component: ShareImportProduct
            },
            {
              path: '/system/ls-type',
              name: 'sys-type',
              meta: { requiresAuth:true, authorize: [Role.Super_Admin],business:'system'},
              component: ShareType
            },
            {
              path: '/system/ls-supply',
              name: 'sys-supply',
              meta: { requiresAuth:true, authorize: [Role.Super_Admin],business:'system'},
              component: ShareSupply
            },
            {
              path: '/system/rpt-user-working',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'system'},
              component: Share_Report_User_Working
            },
            {
              path: '/system/rpt-income-per-day',
              name: 'sys-baocao',
              meta: { requiresAuth: true,authorize: [Role.Super_Admin],business:'system'},
              component: Share_Report_Profit
            },
            {
              path: '/system/rpt-company-activity',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'system'},
              component: Share_Report_Company_activity
            },
            {
              path: '/system/rpt-im-ex-re',
              meta: { requiresAuth:true, authorize: [Role.Supper_Admin],business:'system'},
              component: Share_Report_ImEx
            },
            {
              path: '/system/rpt-client-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'system'},
              component: Share_Report_Client_Debt
            },
            {
              path: '/system/rpt-supply-debt',
              meta: { requiresAuth:true, authorize: [Role.Admin,Role.Branch_Admin],business:'system'},
              component: Share_Report_Supply_Debt
            }
          ]
        },



        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'tables',
          redirect: '/tables/tables',
          name: 'Tables',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'tables',
              name: 'Basic tables',
              component: Tables
            },
            {
              path: 'advanced-tables',
              name: 'Advanced tables',
              component: AdvancedTables
            }
          ]
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'users',
          meta: { label: 'Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            },
            {
              path: 'loading-buttons',
              name: 'Loading Buttons',
              component: LoadingButtons
            }
          ]
        },
        {
          path: 'editors',
          redirect: '/editors/text-editors',
          name: 'Editors',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'text-editors',
              name: 'Text Editors',
              component: TextEditors
            },
            {
              path: 'code-editors',
              name: 'Code Editors',
              component: CodeEditors
            }
          ]
        },
        {
          path: 'forms',
          redirect: '/forms/basic-forms',
          name: 'Forms',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'basic-forms',
              name: 'Basic Forms',
              component: BasicForms
            },
            {
              path: 'advanced-forms',
              name: 'Advanced Forms',
              component: AdvancedForms
            },
            {
              path: 'validation-forms',
              name: 'Form Validation',
              component: ValidationForms
            },
            {
              path: 'multi-select',
              name: 'Multiselect',
              component: MultiSelect
            },
          ]
        },
        {
          path: 'google-maps',
          name: 'Google Maps',
          component: GoogleMaps
        },
        // {
        //   path: 'icons',
        //   redirect: '/icons/font-awesome',
        //   name: 'Icons',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'coreui-icons',
        //       name: 'CoreUI Icons',
        //       component: CoreUIIcons
        //     },
        //     {
        //       path: 'flags',
        //       name: 'Flags',
        //       component: Flags
        //     },
        //     {
        //       path: 'brands',
        //       name: 'Brands',
        //       component: Brands
        //     }
        //   ]
        // },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            },
            {
              path: 'toaster',
              name: 'Toaster',
              component: Toaster
            }
          ]
        },
        {
          path: 'plugins',
          redirect: '/plugins/draggable',
          name: 'Plugins',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'draggable',
              name: 'Draggable Cards',
              component: Draggable
            },
            {
              path: 'calendar',
              name: 'Calendar',
              component: Calendar
            },
            {
              path: 'spinners',
              name: 'Spinners',
              component: Spinners
            }
          ]
        },
       
        {
          path: 'apps',
          name: 'Apps',
          redirect: '/apps/invoicing/invoice',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'invoicing',
              redirect: '/apps/invoicing/invoice',
              name: 'Invoicing',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'invoice',
                  name: 'Invoice',
                  component: Invoice
                }
              ]
            }
          ]
        }
      ]
    },
    // {
    //   path: '/apps/email',
    //   redirect: '/apps/email/inbox',
    //   name: 'Email',
    //   component: EmailApp,
    //   children: [{
    //       path: 'compose',
    //       name: 'Compose',
    //       component: Compose
    //     },
    //     {
    //       path: 'inbox',
    //       name: 'Inbox',
    //       component: Inbox
    //     },
    //     {
    //       path: 'message',
    //       name: 'Message',
    //       component: Message
    //     }
    //   ]
    // },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        // {
        //   path: 'login',
        //   name: 'Login',
        //   component: Login
        // },
        // {
        //   path: '/sign-up',
        //   name: 'sign-up',
        //   component: () => import("@/views/uiregistration/SignupBox.vue"),
        // }
      ]
    }
  ]
})
