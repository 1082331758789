import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'
import { Business } from '@/helpers/business.js'

export const returnService = {
  get_by_id,
  create,
  update,
  delete_obj,
  recover_obj,
  bill_pdf,
  get_ls_return
}

function get_ls_return(param) {
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/return/list`,requestOptions)
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/return/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/return/recover/${id}`,{headers: authHeader()})
}

function create(param) {
  if(param.business==Business.vlxd){
    return Axios.post(`${apiUrl}/return-vlxd/create`,param,{headers:authHeader()})
  }else if(param.business==Business.spa || param.business==Business.oto){
    return Axios.post(`${apiUrl}/return-spa-oto/create`,param,{headers:authHeader()})
  }else if(param.business==Business.cafe || param.business==Business.karaoke ||param.business==Business.food || param.business==Business.football || param.business==Business.hotel || param.business==Business.hostel
    || param.business==Business.bida){
    return Axios.post(`${apiUrl}/return-cafe-karaoke-food-football-hotel-hostel-bida/create`,param,{headers:authHeader()})
  }

  return Axios.post(`${apiUrl}/return-dentist-pharmacy-pawn-tole-water/create`,param,{headers:authHeader()})
}

function update(param) {
  return Axios.put(`${apiUrl}/return/update/${param.id}`,param,{headers:authHeader()})
}

function get_by_id(id) {
  return Axios.get(`${apiUrl}/return/by-id/${id}`,{headers: authHeader()})
}

function bill_pdf(param) {
  const requestOptions = {
    method: 'GET',
    responseType: 'arraybuffer',
    headers: authHeader()
  }
  return Axios.get(`${apiUrl}/return/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
}
