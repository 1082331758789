import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const branchService = {
  get_ls_branch,
  get_by_id,
  create,
  update,
  phone_unique,
  delete_obj,
  recover_obj,
  get_ls_branch_ddl,
  code_unique,
  update_branch_profile,
  name_unique
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/branch/delete/${id}`,{headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/branch/recover/${id}`,{headers: authHeader()})
}

function name_unique(param){
  return Axios.get(`${apiUrl}/branch/name-unique/${param.company_id}?name=${param.name}`,{headers: authHeader()})
}

function phone_unique(param){
  return Axios.get(`${apiUrl}/branch/phone-unique/${param.company_id}?phone=${param.phone}`,{headers: authHeader()})
}

function code_unique(param){
  return Axios.get(`${apiUrl}/branch/code-unique/${param.company_id}?code=${param.code}`,{headers: authHeader()})
}
function get_ls_branch(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/branch/list`,requestOptions)
}

function get_ls_branch_ddl(company_id){
  return Axios.get(`${apiUrl}/branch/ddl/${company_id}`,{headers: authHeader()})
}

function get_by_id(param) {
  return Axios.get(`${apiUrl}/branch/one/${param.company_id}?branch_id=${param.branch_id}`,{headers: authHeader()})
}

function create(dl) {
  return Axios.post(`${apiUrl}/branch/create`,dl,{headers:authHeader()})
}
function update(dl) {
  return Axios.put(`${apiUrl}/branch/update/${dl.id}`,dl,{headers:authHeader()})
}

function update_branch_profile(branch) {
  return Axios.put(`${apiUrl}/branch/update/${branch.id}`,branch,{headers:authHeader()})
}
