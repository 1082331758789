import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const supplyService = {
  get_ls_supply,
  get_ls_supply_ddl,
  get_by_id,
  create,
  update,
  delete_obj,
  recover_obj,
  name_unique,
  code_unique
}

function name_unique(param){
  return Axios.get(`${apiUrl}/supply/name-unique/${param.company_id}?name=${param.name}`,{headers: authHeader()})
}

function code_unique(param){
  return Axios.get(`${apiUrl}/supply/code-unique/${param.company_id}?code=${param.code}`,{headers: authHeader()})
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/supply/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/supply/recover/${id}`,{headers: authHeader()})
}

function get_ls_supply(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/supply/list`,requestOptions)
}

function create(supply) {
  return Axios.post(`${apiUrl}/supply/create`,supply,{headers:authHeader()})
}

function update(supply) {
  return Axios.put(`${apiUrl}/supply/update/${supply.id}`,supply,{headers:authHeader()})
}

function get_by_id(id) {
  return Axios.get(`${apiUrl}/supply/get-by-id/${id}`,{headers: authHeader()})
}

function get_ls_supply_ddl(param){
  return Axios.get(`${apiUrl}/supply/ddl/${param.company_id}`, {headers: authHeader()})
}
