import { branchService } from '../services'
import Vue from 'vue'

const http_get_ls_branch = async (params) => {
  const { data } = await branchService.get_ls_branch(params)
  return data
}

export const st_branch = {
  namespaced: true,
  state: {
    ls_branch: [],
    filter: null,
    page:1,
    ls_branch_ddl:[],
    page_count:0,
    per_page:10
  },
  getters:{
    ls_branch: state => state.ls_branch,
    per_page: state => state.per_page,
    filter: state => state.filter,
    page: state => state.page,
    page_count: state => state.page_count,
    ls_branch_ddl:state=>state.ls_branch_ddl
  },
  actions: {
    async delete_obj({commit},id){
      return await branchService.delete_obj(id)
    },
    async recover_obj({commit},id){
        return await branchService.recover_obj(id)
    },
    async phone_unique({commit},phone){
      return await branchService.phone_unique(phone)
    },
    async name_unique({commit},param){
      return await branchService.name_unique(param)
    },
    async code_unique({commit},param){
      return await branchService.code_unique(param)
    },
    async create({commit},branch){
      return await branchService.create(branch)
    },

    async update({commit},branch){
      return await branchService.update(branch)
    },

    async get_ls_branch ({ state, commit }) {
      debugger
      const data = await http_get_ls_branch({
        lang: state.lang,
        per_page:state.per_page,
        page: state.page,
        text: state.filter
      })
      commit('set_ls_branch', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    get_ls_branch_ddl ({ state, commit },company_id) {
      branchService.get_ls_branch_ddl(company_id)
          .then(
              dl => commit('set_ls_branch_ddl', dl)
          )
    },
    async change_page ({ state, commit }, page) {
      const data = await http_get_ls_branch({
        lang: state.lang,
        per_page:state.per_page,
        page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_branch', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }) {
      const data = await http_get_ls_branch({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_branch', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async get_by_id ({ state, commit },param) {
      debugger
      const res = await branchService.get_by_id(param)
      return res
    },
    async update_branch_profile({commit},branch){
      var loader = Vue.$loading.show()
      let temp= await branchService.update_branch_profile(branch)
      loader.hide()
      return temp
    }
  },
  mutations: {
    set_ls_branch (state, { items, pages,per_page }) {
      state.ls_branch = items
      state.page_count = pages
      state.per_page = per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    },
    set_ls_branch_ddl(state, items) {
      state.ls_branch_ddl = items.data
    }
  }
}
