import 'core-js/stable'
import Vue from 'vue'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import { iconsSet as icons } from './assets/icons/icons.js'
import i18n from './lang/i18n'


import BootstrapVue from 'bootstrap-vue'
import axios from './axios.js'
import VueAxios from 'vue-axios'
import { store } from './store/index'
import router from './router'
import Vuelidate from 'vuelidate'
import pdfObject from 'pdfobject'
import moment from 'moment-timezone'
import Notifications from 'vue-notification'
import VueToastr2 from 'vue-toastr-2'
import 'moment-timezone'
import Loading from 'vue-loading-overlay'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import {Role} from './helpers/role'
import vSelect from 'vue-select'
import money from 'v-money'

Vue.use(CoreuiVuePro)
Vue.prototype.$log = console.log.bind(console)


Vue.component('v-select', vSelect)
// name is optional
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
// Init plugin
Vue.use(Loading)
window.toastr = require('toastr')
Vue.use(VueToastr2)
Vue.use(Notifications)
Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(Vuelidate)
Vue.use(pdfObject)
Vue.use(money, {precision: 4})


import { Lang } from '@/helpers/lang.js'
import datePicker from 'vue-bootstrap-datetimepicker'
// import the necessary css file
import "vue-awesome-paginate/dist/style.css"
Vue.use(datePicker)
moment.tz.setDefault('Asia/Ho_Chi_Minh')
Vue.mixin({
  data: function () {
    return {
      ppl:[
          {
              id: 57,
              name: '57'
          },
          {
              id: 80,
              name: '80'
          },
          {
              id: 5,
              name: 'A5'
          },
          {
              id: 4,
              name: 'A4'
          }
      ],
      ppl2:[
          {
              id: 57,
              name: '57'
          },
          {
              id: 80,
              name: '80'
          }
      ],
      ppl45:[
        {
          id: 5,
          name: 'A5'
        },
        {
          id: 4,
          name: 'A4'
        }
      ],
      default_str:"data:image/webp;base64,",
      bill_name:{"en":"Bill","vi":"Tạm tính"},
      optionLangs: [
        {
          name: 'VN',full_name: 'Vietnam',
          code: 'vi'
        },
        {
          name: 'EN',full_name: 'English',
          code: 'en'
        }
      ],
      option_business:[
      { id: 1, name: {"en":"Coffee management system","vi":"Quản lý kinh doanh cafe"},code:'cafe',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/cafe-icon.png?alt=media&token=b2620335-c0d4-4f48-9b58-2ee9bae384a0' },

      { id: 2, name: {"en":"Material building management system","vi":"Quản lý bán vật liệu xây dựng"},code:'vlxd',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/vlxd-new.png?alt=media&token=204386f4-d4b8-4568-85ea-8ec3bfd06d76' },

      { id: 3, name: {"en":"Dentist magagement system","vi":"Quản lý phòng khám răng"},code:'dentist',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/dentist-new.png?alt=media&token=b0bab8d8-ec52-4d9b-9ceb-8dbea01b1884' },

      { id: 4, name: {"en":"Pharmacy magagement system","vi":"Quản lý nhà thuốc"},code:'pharmacy',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/thuoc-tay-new.png?alt=media&token=73786489-0982-428d-b384-bfebb4b3e53a' },

      { id: 5, name: {"en":"Karaoke magagement system","vi":"Quản lý kinh doanh Karaoke"},code:'karaoke',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/karaoke-new.png?alt=media&token=bdb35737-d51a-4081-b5a1-3cebf5a6257f' },

      { id: 6, name: {"en":"Restaurent magagement system","vi":"Quản lý kinh doanh quán ăn"},code:'food',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/food-new.png?alt=media&token=61f07499-9bac-453e-b1c8-a8cc3b38ce67' },

      { id: 7, name: {"en":"Pawnshop magagement system","vi":"Quản lý kinh doanh cầm đồ"},code:'pawn',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/camdo-new.png?alt=media&token=a19d3bfa-a377-4586-8df7-c0b304e4566b' },

      { id: 8, name: {"en":"Football magagement system","vi":"Quản lý sân bóng đá mini"},code:'football',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/bongda-new.png?alt=media&token=7f1812eb-adec-4669-a313-de9b089fc971' },

      { id: 9, name: {"en":"Tole, still magagement system","vi":"Quản lý kinh doanh Tole, Sắt Thép"},code:'tole',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/tole-sat-thep-new.png?alt=media&token=e454c777-3c31-4fb1-b86e-ccebbef8d2b9' },

      { id: 10, name: {"en":"Hotel magagement system","vi":"Quản lý kinh doanh hotel"},code:'hotel',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/hotel-new.png?alt=media&token=1e440e79-63ad-4b8a-9717-7727605a0690' },

      { id: 11, name: {"en":"Hostel magagement system","vi":"Quản lý kinh doanh nhà trọ"},code:'hostel',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/hotel-new.png?alt=media&token=1e440e79-63ad-4b8a-9717-7727605a0690' },

      { id: 12, name: {"en":"Store magagement system","vi":"Quản lý kinh doanh Bia, nước giải khát"},code:'water',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/vlxd-new.png?alt=media&token=204386f4-d4b8-4568-85ea-8ec3bfd06d76' },

      { id: 13, name: {"en":"Biliard magagement system","vi":"Quản lý bàn Bida"},code:'bida',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/bida-new.png?alt=media&token=e8cd6efd-106e-4f85-a1ea-9b60f2e5f1a4' },

      { id: 14, name: {"en":"Spa magagement system","vi":"Quản lý trung tâm SPA"},code:'spa',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/spa-icon.png?alt=media&token=66738bcd-e3f0-4816-9575-1aef5c060da1' },

      { id: 15, name: {"en":"Repair [oto, bike] magagement system","vi":"Quản lý sửa chữa ô tô"},code:'oto',
      url:'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/oto-icon.png?alt=media&token=ba09d9c9-02c0-4856-808b-b8c838197fc7' }
      ],
      option_gender: [
        { id: 1, name: {"vi":"Nam","en":"Male"}},
        { id: 2, name: {"vi":"Nữ","en":"Female"}},
        { id: 0, name: {"vi":"Khác","en":"Other"}}
      ],
      ls_payment_ddl:[
        { id: 1, name: {"vi":"Tiền mặt","en":"Cash"} },
        { id: 2, name: {"vi":"Chuyển khoản","en":"Online"} },
        { id: 3, name: {"vi":"Khác","en":"Khác"} }
      ],
      ls_order_status:[
        { id: 1, name: {"vi":"Đang order","en":"Ordering"} },
        { id: 0, name: {"vi":"Hoàn tất","en":"Finished"} },
        { id: -1, name: {"vi":"Đã xóa","en":"Deleted"} }
      ],
      ls_treatment_status:[
        { id: 1, name: {"vi":"Đang điều trị","en":"Being treated"} },
        { id: 0, name: {"vi":"Hoàn tất","en":"Finished"} },
        { id: -1, name: {"vi":"Đã xóa","en":"Deleted"} }
      ],
      ls_delivery_status:[
        { id: 1, name: {"vi":"Đang giao","en":"Deliverying"} },
        { id: 0, name: {"vi":"Hoàn tất","en":"Finished"} },
        { id: -1, name: {"vi":"Đã xóa","en":"Deleted"} }
      ]
    }
  },
  methods: {
    get_user_status(){
      return [
        {value:0,text: this.$t("common.new")},
        {value:1,text: this.$t("common.using")},
        {value:-1,text: this.$t("common.deleted")}
      ]
    },
    getDeliveryStatus (status) {
      let item= this.ls_delivery_status.find(x=>x.id==parseInt(status))
      if(item){
        return item.name[this.$i18n.locale]
      }

      return null
    },
    getOrderStatus (status) {
      let item= this.ls_order_status.find(x=>x.id==parseInt(status))
      if(item){
        return item.name[this.$i18n.locale]
      }

      return null
    },
    getTreatmentStatus (status) {
      let item= this.ls_treatment_status.find(x=>x.id==parseInt(status))
      if(item){
        return item.name[this.$i18n.locale]
      }

      return null
    },
    getObjectStatus (status) {
      return status === 1 ? this.$t("common.using")
        : status === 0 ? this.$t("common.new"): this.$t("common.deleted")
    },
    get_gender(id){
      let gender=this.option_gender.find(x=>x.id==id)
      if(gender){
        return gender.name[this.$i18n.locale]
      }

      return null
    },
    computed_logo(str){
      return this.default_str+str.split(",")[1]
    },
    getBadge (status) {
      return status === 1 ? 'success'
        : status === 0 ? 'primary': 'danger'
    },
    getStatusText (status) {
      return status === 1 ? this.$t("common.using")
        : status === 0 ? this.$t("common.finish"): this.$t("common.deleted")
    },
    callSetLangActions (lang) {
      debugger
      let currentUser = JSON.parse(localStorage.getItem('user'))
  
      if (currentUser) {
        this.$store.dispatch('st_user/setLang', lang)
      }
    },
    capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
    formatPrice(value) {
      // let val = (value/1).toFixed(2).replace('.', ',')
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    DateFormat: function(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    VnDateFormat: function(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    VnDateTimeFormat: function(date) {
      if(this.$i18n.locale==Lang.vi){
        return moment(date).format('DD-MM-YYYY HH:mm:ss')
      }else{
        return moment(date).format('MM-DD-YYYY HH:mm:ss')
      }
    },
    async serverTime(){
      return moment().format('YYYY-MM-DD HH:mm:ss A')
    },

    isNumeric:function(n) {
        return !isNaN(parseFloat(n)) && isFinite(n)
    },
  }
})

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta
  const { business } = to.meta
  debugger
  if (authorize) {
    let currentUser = JSON.parse(localStorage.getItem('user'))

    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/login', query: { returnUrl: to.path } })
    }

    if(currentUser.role==Role.Sub_Admin) return next()

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.role)) {
      // role not authorised so redirect to home page
      return next({ path: '/' })
    }

    // check if have not business
    if(currentUser.company.business!=business){
      // role not authorised so redirect to home page
      return next({ path: '/not-found' })
    }
  }

  next()
})


const main=new Vue({
  el: '#app',
  i18n,
  created:function(){
    (function(d,s,id){
      var js
      var fjs=d.getElementsByTagName(s)[0]
      if(d.getElementById(id)) return
      js=d.createElement(s)
      js.id=id
      js.src="https://connect.facebook.net/vi_VN/sdk.js#xfbml=1&version=v9.0"
      fjs.parentNode.insertBefore(js,fjs)
    }(document,'script','facebook-jssdk'))
  },
  router,
  store,
  mounted () {
    window.fbAsyncInit = function() {
      FB.init({
        appId            : '393379721845558',
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.3'
      });
    };
  },
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: '<App/>',
  components: {
    App
  }
})

export default main