import Axios from 'axios';
import apiUrl from '../utils/api';
import { authHeader } from '../helpers';

export const utilService = {
    get_ds_loduthuong,
    serverTime
};

function serverTime(){
  return Axios.get(`${apiUrl}/vn-time`, {headers: authHeader()})
}

function get_ds_loduthuong(){
    return Axios.get(`${apiUrl}/ds-lo-du-thuong`, {headers: authHeader()})
}
