import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'

export const inoutService = {
  get_ls_inout,
  pdf_user_working,
  excel_user_working
}
function get_ls_inout(param) {
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/inout/list-user-working/${param.company_id}`,requestOptions)
}

function pdf_user_working(param){
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
    params:param
  }
  return Axios.post(`${apiUrl}/inout/pdf-user-working/${param.data.company_id}`,param,requestOptions)
}

function excel_user_working(param){
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
    params:param
  }
  debugger
  return Axios.post(`${apiUrl}/inout/excel-user-working/${param.company_id}`,param,requestOptions)
}

