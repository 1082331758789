import { typeService } from '../services'

const http_get_ls_type = async (params) => {
  const { data } = await typeService.get_ls_type(params)
  return data
}

export const st_type = {
  namespaced: true,
  state: {
    ls_type:[],
    ls_type_ddl:[],
    page: 1,
    per_page:10,
    page_count: 0,
    filter: null
  },
  getters:{
    page: state => state.page,
    ls_type_ddl: state => state.ls_type_ddl,
    page_count: state => state.page_count,
    filter: state => state.filter,
    per_page:state=>state.per_page,
    ls_type:state=>state.ls_type
  },
  actions: {
    async delete_obj({commit},id){
      return await typeService.delete_obj(id)
    },

    async recover_obj({commit},id){
      return await typeService.recover_obj(id)
    },

    async name_unique({commit},params){
      return await typeService.name_unique(params)
    },

    async code_unique({commit},params){
      return await typeService.code_unique(params)
    },

    async create({commit},cf){
      let res= await typeService.create(cf)
      debugger
      return res
    },

    async update({commit},cf){
      let res= await typeService.update(cf)
      debugger
      return res
    },

    async get_ls_type ({ state, commit }) {
      const data = await http_get_ls_type({
        lang: state.lang,
        per_page:state.per_page,
        page: state.page,
        text: state.filter
      })
      commit('set_ls_type', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page ({ state, commit }, page) {
      const data = await http_get_ls_type({
        lang: state.lang,
        per_page:state.per_page,
        page,
        text: state.filter
      })

      commit('set_page', page)
      commit('set_ls_type', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }, filter) {
      const data = await http_get_ls_type({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: filter
      })
      commit('set_page', 1)
      commit('set_ls_type', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async get_by_id ({ state, commit },id) {
        return await typeService.get_by_id(id)
    },
    get_ls_type_ddl ({ state, commit },company_id) {
      typeService.get_ls_type_ddl(company_id)
      .then(
        type => commit('set_ls_type_ddl', type)
      )
    }
  },
  mutations: {
    set_ls_type (state, { items, pages,per_page }) {
      debugger
      state.ls_type = items
      state.page_count = pages
      state.per_page=per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    },
    set_ls_type_ddl(state, items) {
      state.ls_type_ddl = items.data
    }
  }
}
