import { objectService } from '../services'

const http_get_ls_object = async (params) => {
  const { data } = await objectService.get_ls_object(params)
  return data
}

export const st_object = {
  namespaced: true,
  state: {
    ls_object:[],
    ls_object_ddl:[],
    ls_object_has_order_ddl:[],
    page_object: 1,
    per_page_object:10,
    page_count_object: 0,
    filter_object: null
  },
  getters:{
    page_object: state => state.page_object,
    page_count_object: state => state.page_count_object,
    filter_object: state => state.filter_object,
    per_page_object:state=>state.per_page_object,
    ls_object_ddl:state=>state.ls_object_ddl,
    ls_object_has_order_ddl:state=>state.ls_object_has_order_ddl,
    ls_object:state=>state.ls_object
  },
  actions: {
    async delete_obj({commit},id){
      return await objectService.delete_obj(id)
    },

    async recover_obj({commit},id){
      return await objectService.recover_obj(id)
    },

    async name_unique({commit},params){
      return await objectService.name_unique(params)
    },

    async code_unique({commit},params){
      return await objectService.code_unique(params)
    },

    async create({commit},cf){
      let res= await objectService.create(cf)
      debugger
      return res
    },

    async update({commit},cf){
      let res= await objectService.update(cf)
      debugger
      return res
    },

    async get_ls_object ({ state, commit }) {
      const data = await http_get_ls_object({
        lang: state.lang,
        per_page:state.per_page_object,
        page: state.page_object,
        text: state.filter_object
      })
      commit('set_ls_object', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page_object ({ state, commit }, page) {
      const data = await http_get_ls_object({
        lang: state.lang,
        per_page:state.per_page_object,
        page,
        text: state.filter_object
      })

      commit('set_page', page)
      commit('set_ls_object', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter_object ({ state, commit }, filter) {
      debugger
      const data = await http_get_ls_object({
        lang: state.lang,
        per_page:state.per_page_object,
        page: 1,
        text: filter
      })
      commit('set_page', 1)
      commit('set_ls_object', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async get_by_id ({ state, commit },id) {
        return await objectService.get_by_id(id)
    },
    get_ls_object_ddl ({ state, commit },param) {
      objectService.get_ls_object_ddl(param)
      .then(
        obj => commit('set_ls_object_ddl', obj)
      )
    },
    get_ls_object_has_order_ddl ({ state, commit },param) {
      objectService.get_ls_object_has_order_ddl(param)
      .then(
        obj => commit('set_ls_object_has_order_ddl', obj)
      )
    }
  },
  mutations: {
    set_ls_object (state, { items, pages,per_page }) {
      state.ls_object = items
      state.page_count_object = pages
      state.per_page_object=per_page
    },
    set_page (state, page) {
      state.page_object = page
    },
    set_per_page (state, set_page) {
      state.per_page_object = set_page
    },
    set_filter (state, filter) {
      debugger
      state.filter_object = filter
    },
    set_ls_object_ddl(state, items) {
      state.ls_object_ddl = items.data
    },
    set_ls_object_has_order_ddl(state, items) {
      state.ls_object_has_order_ddl = items.data
    }
  }
}
