import { returnService } from '../services'
import Vue from 'vue'

const http_get_ls_return = async (params) => {
  const { data } = await returnService.get_ls_return(params)
  return data
}

export const st_return = {
  namespaced: true,
  state: {
    ls_return: [],
    page: 1,
    per_page:10,
    page_count: 0,
    filter: null
  },
  getters:{
    filter: state => state.filter,
    page: state => state.page,
    per_page: state => state.per_page,
    page_count: state => state.page_count,
    ls_return:state=>state.ls_return
  },
  actions: {
    // ls return
    async apply_filter ({ state, commit }) {
      var loader = Vue.$loading.show()
      const data = await http_get_ls_return({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_return', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
      loader.hide()
    },
    async get_ls_return ({ state, commit }) {
      debugger
      const data = await http_get_ls_return({
        lang: state.lang,
        per_page:state.per_page,
        page: state.page,
        text: state.filter
      })
      debugger
      commit('set_ls_return', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async change_page ({ state, commit }, page) {
      debugger
      const data = await http_get_ls_return({
        lang: state.lang,
        per_page:state.per_page,
        page: page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_return', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async bill_pdf({ commit },param){
      var loader = Vue.$loading.show()
      let temp= await returnService.bill_pdf(param)
      loader.hide()
      return temp
    },
    async delete_obj({commit},id){
      return await returnService.delete_obj(id)
    },
    async recover_obj({commit},id){
      return await returnService.recover_obj(id)
    },

    async create({commit},so){
      var loader = Vue.$loading.show()
      let res= await returnService.create(so)
      loader.hide()
      return res
    },
    async update({commit},so){
      var loader = Vue.$loading.show()
      let res= await returnService.update(so)
      loader.hide()
      return res
    },
    async get_by_id ({ state, commit },id) {
      debugger
      const res = await returnService.get_by_id(id)
      return res
    },
    async in_pdf({ state, commit },params){
      return await returnService.pdf(params)
    }
  },
  mutations: {
    set_ls_return (state, { items, pages,per_page }) {
      state.ls_return = items
      state.page_count = pages
      state.per_page=per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    }
  }
}
