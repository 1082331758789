import { deliveryService,objectService,
  clientService } from '../services'
import Vue from 'vue'

const http_get_ls_delivery = async (params) => {
  const { data } = await deliveryService.get_ls_delivery(params)
  return data
}

export const st_delivery = {
  namespaced: true,
  state: {
    ls_delivery: [],
    page: 1,
    per_page:10,
    page_count: 0,
    filter: null
  },
  getters:{
    filter: state => state.filter,
    page: state => state.page,
    per_page: state => state.per_page,
    page_count: state => state.page_count,
    ls_delivery:state=>state.ls_delivery
  },
  actions: {
    // ls delivery
    async apply_filter ({ state, commit }) {
      var loader = Vue.$loading.show()
      const data = await http_get_ls_delivery({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_delivery', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
      loader.hide()
    },
    async get_ls_delivery ({ state, commit }) {
      debugger
      const data = await http_get_ls_delivery({
        lang: state.lang,
        per_page:state.per_page,
        page: state.page,
        text: state.filter
      })
      debugger
      commit('set_ls_delivery', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async change_page ({ state, commit }, page) {
      debugger
      const data = await http_get_ls_delivery({
        lang: state.lang,
        per_page:state.per_page,
        page: page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_delivery', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async bill_pdf({ commit },param){
      var loader = Vue.$loading.show()
      let temp= await deliveryService.bill_pdf(param)
      loader.hide()
      return temp
    },
    async get_ls_client_name_suggest({commit},param){
      debugger
      return await clientService.get_ls_client_name_suggest(param)
    },
    async get_ls_project_name_suggest({commit},param){
      debugger
      return await objectService.get_ls_object_name_suggest(param)
    },
    async delete_obj({commit},id){
      return await deliveryService.delete_obj(id)
    },
    async recover_obj({commit},id){
      return await deliveryService.recover_obj(id)
    },

    async create({commit},so){
      var loader = Vue.$loading.show()
      let res= await deliveryService.create(so)
      loader.hide()
      return res
    },
    async update({commit},so){
      var loader = Vue.$loading.show()
      let res= await deliveryService.update(so)
      loader.hide()
      return res
    },
    async get_by_id ({ state, commit },id) {
      debugger
      const res = await deliveryService.get_by_id(id)
      return res
    },
    async in_pdf({ state, commit },params){
      return await deliveryService.pdf(params)
    }
  },
  mutations: {
    set_ls_delivery (state, { items, pages,per_page }) {
      state.ls_delivery = items
      state.page_count = pages
      state.per_page=per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    }
  }
}
