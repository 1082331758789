import { inoutService } from '../services'
import Vue from 'vue'

const http_get_ls_inout = async (params) => {
  const { data } = await inoutService.get_ls_inout(params)
  return data
}

export const st_inout = {
  namespaced: true,
  state: {
    ls_inout:[],
    page: 1,
    page_count: 0,
    from_date:null,
    to_date: null,
    branchs:[],
    per_page:50,
    company_id:null,
    has_son:false
  },
  getters:{
    ls_inout:state=>state.ls_inout,
    branchs: state => state.branchs,
    page: state => state.page,
    page_count: state => state.page_count,
    per_page:state=>state.per_page,
    from_date: state => state.from_date,
    to_date: state => state.to_date,
    company_id: state => state.company_id,
    has_son: state => state.has_son
  },
  actions: {
    async get_ls_inout ({ state, commit }) {
      debugger
      let ids=null
      if(state.branchs.length>0){
        ids=state.branchs.map(function(item) {
          return item['id']
        }).join()
      }
      const data = await http_get_ls_inout({
        lang: state.lang,
        from_date:state.from_date,
        to_date: state.to_date,
        branchs: ids,
        company_id: state.company_id,
        per_page:state.per_page,
        page: state.page
      })
      commit('set_ls_inout', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page ({ state, commit }, page) {
      debugger
      let ids=null
      if(state.branchs.length>0){
        ids=state.branchs.map(function(item) {
          return item['id']
        }).join()
      }
      const data = await http_get_ls_inout({
        lang: state.lang,
        from_date:state.from_date,
        to_date: state.to_date,
        branchs: ids,
        company_id: state.company_id,
        per_page:state.per_page,
        page
      })

      commit('set_page', page)
      commit('set_ls_inout', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }, filter) {
      debugger
      let ids=null
      if(state.branchs.length>0){
        ids=state.branchs.map(function(item) {
          return item['id']
        }).join()
      }
      const data = await http_get_ls_inout({
        lang: state.lang,
        from_date:state.from_date,
        to_date: state.to_date,
        branchs: ids,
        company_id: state.company_id,
        per_page:state.per_page,
        page: 1
      })
      debugger
      commit('set_page', 1)
      commit('set_ls_inout', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async pdf_user_working({ state, commit },lang){
      var loader = Vue.$loading.show()
      let ids=null
      if(state.branchs.length>0){
        ids=state.branchs.map(function(item) {
          return item['id']
        }).join()
      }
      let res= await inoutService.pdf_user_working({"lang":lang,has_son:state.has_son,data:{company_id:state.company_id,
        branchs:ids,from_date:state.from_date,
        to_date: state.to_date}})
      loader.hide()
      return res
    },
    async excel_user_working({ state, commit },lang){
      var loader = Vue.$loading.show()
      let ids=null
      if(state.branchs.length>0){
        ids=state.branchs.map(function(item) {
          return item['id']
        }).join()
      }
      let res= await inoutService.excel_user_working([{"lang":lang,has_son:state.has_son,data:{company_id:state.company_id,
        branchs:ids,from_date:state.from_date,
        to_date: state.to_date}}])
      loader.hide()
      return res
    }
  },
  mutations: {
    set_ls_inout (state, { items, pages,per_page }) {
      state.ls_inout = items
      state.page_count = pages
      state.per_page=per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_from_date (state, date) {
      state.from_date = date
    },
    set_to_date (state, date) {
      state.to_date = date
    },
    set_branchs (state, ls) {
      state.branchs = ls
    },
    set_company_id (state, id) {
      state.company_id = id
    },
    set_has_son (state, flag) {
      state.has_son = flag
    }
  }
}
