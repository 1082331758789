import { chamcongService } from '../services';

const http_get_ls_chamcong = async (params) => {
  const { data } = await chamcongService.get_ls_chamcong(params)
  debugger
  return data
}

export const st_chamcong = {
    namespaced: true,
    state: {
      ls_chamcong: [],
      page: 1,
      page_count: 0,
      per_page:0,
      filter: null,
      lang:'en'
    },
    getters:{
      ls_chamcong: state => state.ls_chamcong,
      page: state => state.page,
      page_count: state => state.page_count,
      filter: state => state.filter,
      per_page:state=>state.per_page
    },
    actions: {
      async phone_unique({commit},phone){
        return await chamcongService.phone_unique(phone)
      },
      async ma_unique({commit},ma){
        return await chamcongService.ma_unique(ma)
      },
      async create({commit},company){
        return await chamcongService.create(company)
      },

      async update({commit},company){
        return await chamcongService.update(company)
      },

      async get_ls_chamCong ({ state, commit }) {
        debugger
        const data = await http_get_ls_chamcong({
          page: state.page,
          lang: state.lang,
          per_page:state.per_page,
          text: state.filter
        })
        debugger
        commit('set_ls_chamcong', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
          per_page:data.paginated.per_page})
      },

      async change_page ({ state, commit }, page) {
        const data = await http_get_ls_chamcong({
          lang: state.lang,
          per_page:state.per_page,
          page,
          text: state.filter
        })
        commit('set_page', page)
        commit('set_ls_chamcong', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
          per_page:data.paginated.per_page})
      },

      async applyFilter ({ state, commit }) {
        const data = await http_get_ls_chamcong({
          lang: state.lang,
          per_page:state.per_page,
          page: 1,
          text: state.filter
        })
        commit('set_page', 1)
        commit('set_ls_chamcong', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
          per_page:data.paginated.per_page})
      },

      async delete_obj({commit},id){
        return await chamcongService.delete_obj(id)
      },
      async recover_obj({commit},id){
        let co= await chamcongService.recover_obj(id)
        return co
      }
    },
    mutations: {
      set_ls_chamcong (state, { items, pages,per_page }) {
        state.ls_chamcong = items
        state.page_count = pages,
        state.per_page = per_page
      },
      set_page (state, page) {
        state.page = page
      },
      set_filter (state, filter) {
        state.filter = filter
      }
    }
}
