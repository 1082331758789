import Vue from 'vue'
import Vuex from 'vuex'
import { st_responsive } from './responsive.module'
import { st_alert } from './alert.module'
import { st_authentication } from './authentication.module'
import { st_user } from './user.module'
import { st_branch } from './branch.module'
import { utils } from './util.module'
import { st_category } from './category.module'
import { st_product } from './product.module'
import { st_company } from './company.module'
import { st_chamcong } from './chamcong.module'
import { st_unit } from './unit.module'
import { st_other } from './other.module'
import { st_order } from './order.module'
import { st_delivery } from './delivery.module'
import { st_client } from './client.module'
import { st_object } from './object.module'
import { st_supply } from './supply.module'
import { st_invoice } from './invoice.module'

import { st_doctor } from './doctor.module'
import { st_package } from './package.module'
import { st_type } from './type.module'
import { st_rent } from './rent.module'
import { st_car } from './car.module'
import { st_import } from './import.module'
import { st_product_report } from './product_report.module'
import { st_inout } from './inout.module'
import { st_treatment } from './treatment.module'
import { st_staff } from './staff.module'
import { st_return } from './return.module'
import { st_oto } from './oto.module'
import { st_cart } from './cart.module'
import { st_ui } from './ui.module'
Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    st_responsive,
    st_alert,
    st_authentication,
    st_user,
    st_branch,
    utils,
    st_category,
    st_product,
    st_company,
    st_chamcong,
    st_unit,
    st_other,
    st_order,
    st_delivery,
    st_client,
    st_object,
    st_supply,
    st_invoice,
    st_doctor,
    st_package,
    st_type,
    st_rent,
    st_car,
    st_import,
    st_product_report,
    st_inout,
    st_treatment,
    st_staff,
    st_return,
    st_oto,
    st_cart,
    st_ui
  }
})