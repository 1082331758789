import { rentService } from '../services'
import { authHeader } from '../helpers'

const http_get_ls_rent = async (params) => {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  }
  const { data } = await rentService.get_ls_rent(params)
  return data
}

export const st_rent = {
  namespaced: true,
  state: {
    ls_rent: [],
    ls_rent_ddl:[],
    page: 1,
    page_count: 0,
    filter: null,
    per_page:10,
    lang:'en'
  },
  getters:{
    ls_rent: state => state.ls_rent,
    page: state => state.page,
    page_count: state => state.page_count,
    filter: state => state.filter,
    per_page:state=>state.per_page,
    ls_rent_ddl:state=>state.ls_rent_ddl
  },
  actions: {
    get_ls_rent_ddl ({ state, commit },company_id) {
      rentService.get_ls_rent_ddl(company_id)
      .then(
        rent => commit('set_ls_rent_ddl', rent)
      )
    },
    async name_unique({ commit },param){
      debugger
      return await rentService.name_unique(param)
    },

    async delete_obj({commit},id){
      return await rentService.delete_obj(id)
    },

    async recover_obj({commit},id){
      return await rentService.recover_obj(id)
    },

    async phone_unique({ rootState, commit },phone){
      return await rentService.phone_unique(phone)
    },
    async create({commit},rent){
      return await rentService.create(rent)
    },

    async update({commit},rent){
      return await rentService.update(rent)
    },

    async get_ls_rent ({ state, commit }) {
      const data = await http_get_ls_rent({
        lang:state.lang,
        per_page:state.per_page,
        page: state.page,
        filter: state.filter
      })

      commit('set_ls_rent', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page ({ state, commit }, page) {
      const data = await http_get_ls_rent({
        lang: state.lang,
        per_page:state.per_page,
        page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_rent', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }) {
      debugger
      const data = await http_get_ls_rent({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_rent', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    }
  },
  mutations: {
    set_ls_rent (state, { items, pages,per_page }) {
      state.ls_rent = items
      state.page_count = pages
      state.per_page = per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    },
    set_ls_rent_ddl(state, items) {
      state.ls_rent_ddl = items.data
    }
  }
}
