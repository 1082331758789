import { companyService } from '../services'
import Vue from 'vue'

const http_get_ls_company = async (params) => {
  const { data } = await companyService.get_ls_company(params)
  debugger
  return data
}

export const st_company = {
  namespaced: true,
  state: {
    ls_company: [],
    page: 1,
    page_count: 0,
    per_page:10,
    filter: null,
    lang:'en',
    ls_company_ddl:[]
  },
  getters:{
    ls_company: state => state.ls_company,
    ls_company_ddl: state => state.ls_company_ddl,
    page: state => state.page,
    page_count: state => state.page_count,
    filter: state => state.filter,
    per_page:state=>state.per_page
  },
  actions: {
    async get_by_id ({ state, commit },id) {
      debugger
      const res = await companyService.get_by_id(id)
      return res
    },
    get_ls_company_ddl ({ state, commit },company_id) {
      companyService.get_ls_company_ddl(company_id)
      .then(
        company => commit('set_ls_company_ddl', company)
      )
    },

    async phone_unique({commit},phone){
      return await companyService.phone_unique(phone)
    },

    async profile_phone_unique({commit},param){
      return await companyService.profile_phone_unique(param)
    },

    async code_unique({commit},param){
      return await companyService.code_unique(param)
    },

    async create({commit},company){
      return await companyService.create(company)
    },

    async update({commit},company){
      return await companyService.update(company)
    },

    async get_ls_company ({ state, commit }) {
      debugger
      const data = await http_get_ls_company({
        lang: state.lang,
        per_page:state.per_page,
        page: state.page,
        text: state.filter
      })
      debugger
      commit('set_ls_company', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async change_page ({ state, commit }, page) {
      const data = await http_get_ls_company({
        lang: state.lang,
        per_page:state.per_page,
        page,
        text: state.filter
      })
      commit('set_page', page)
      commit('set_ls_company', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async apply_filter ({ state, commit }) {
      const data = await http_get_ls_company({
        lang: state.lang,
        per_page:state.per_page,
        page: 1,
        text: state.filter
      })
      commit('set_page', 1)
      commit('set_ls_company', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },

    async delete_obj({commit},id){
      return await companyService.delete_obj(id)
    },

    async recover_obj({commit},id){
      let co= await companyService.recover_obj(id)
      return co
    },
    async update_company_profile({commit},company){
      var loader = Vue.$loading.show()
      let temp= await companyService.update_company_profile(company)
      loader.hide()
      return temp
    }
  },
  mutations: {
    set_ls_company (state, { items, pages,per_page }) {
      debugger
      state.ls_company = items
      state.page_count = pages,
      state.per_page = per_page
    },
    set_page (state, page) {
      state.page = page
    },
    set_filter (state, filter) {
      state.filter = filter
    },
    set_ls_company_ddl(state, items) {
      state.ls_company_ddl = items.data
    }
  }
}
