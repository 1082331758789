import { orderService,objectService,
  clientService } from '../services'
import Vue from 'vue'

const http_get_ls_order = async (params) => {
  const { data } = await orderService.get_ls_order(params)
  return data
}

export const st_order = {
  namespaced: true,
  state: {
    ls_order: [],
    page_order: 1,
    per_page_order:10,
    page_count_order: 0,
    filter_order: null
  },
  getters:{
    filter_order: state => state.filter_order,
    page_order: state => state.page_order,
    per_page_order: state => state.per_page_order,
    page_count_order: state => state.page_count_order,
    ls_order:state=>state.ls_order
  },
  actions: {
    async code_unique({ rootState, commit },param){
      return await orderService.code_unique(param)
    },
    async merge_object({ state, commit },param){
      var loader = Vue.$loading.show()
      let order= await orderService.merge_object(param)
      loader.hide()
      return order
    },
    async clone_object({ state, commit },param){
      var loader = Vue.$loading.show()
      let order= await orderService.clone_object(param)
      loader.hide()
      return order
    },
    // ls order
    async apply_filter_order ({ state, commit }) {
      var loader = Vue.$loading.show()
      const data = await http_get_ls_order({
        lang: state.lang,
        per_page:state.per_page_order,
        page: 1,
        text: state.filter_order
      })
      commit('set_page', 1)
      commit('set_ls_order', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
      loader.hide()
    },
    async get_ls_order ({ state, commit }) {
      debugger
      const data = await http_get_ls_order({
        lang: state.lang,
        per_page:state.per_page_order,
        page: state.page_order,
        text: state.filter_order
      })
      debugger
      commit('set_ls_order', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async change_page_order ({ state, commit }, page) {
      debugger
      const data = await http_get_ls_order({
        lang: state.lang,
        per_page:state.per_page_order,
        page: page,
        text: state.filter_order
      })
      commit('set_page', page)
      commit('set_ls_order', {items:data.data,pages:parseInt(data.paginated.total%data.paginated.per_page)==0?parseInt(data.paginated.total/data.paginated.per_page):parseInt(data.paginated.total/data.paginated.per_page)+1,
        per_page:data.paginated.per_page})
    },
    async bill_pdf({ commit },param){
      var loader = Vue.$loading.show()
      let temp= await orderService.bill_pdf(param)
      loader.hide()
      return temp
    },
    async get_ls_client_name_suggest({commit},param){
      debugger
      return await clientService.get_ls_client_name_suggest(param)
    },
    async get_ls_project_name_suggest({commit},param){
      debugger
      return await objectService.get_ls_object_name_suggest(param)
    },
    async delete_obj({commit},id){
      return await orderService.delete_obj(id)
    },
    async recover_obj({commit},id){
      return await orderService.recover_obj(id)
    },
    async release_object({commit},param){
      var loader = Vue.$loading.show()
      let res= await orderService.release_object(param)
      loader.hide()
      return res
    },

    async create({commit},so){
      var loader = Vue.$loading.show()
      let res= await orderService.create(so)
      loader.hide()
      return res
    },
    async update({commit},so){
      var loader = Vue.$loading.show()
      let res= await orderService.update(so)
      loader.hide()
      return res
    },
    async get_by_id ({ state, commit },id) {
      debugger
      const res = await orderService.get_by_id(id)
      return res
    },
    async in_pdf({ state, commit },params){
      return await orderService.pdf(params)
    },
    async report_company_revenue({ state, commit },param){
      var loader = Vue.$loading.show()
      let res= await orderService.report_company_revenue(param)
      loader.hide()
      return res
    },
    async report_client_debt({ state, commit },param){
      var loader = Vue.$loading.show()
      let res= await orderService.report_client_debt(param)
      loader.hide()
      return res
    },
    async report_supply_debt({ state, commit },param){
      var loader = Vue.$loading.show()
      let res= await orderService.report_supply_debt(param)
      loader.hide()
      return res
    },
    async report_company_activity({ state, commit },param){
      var loader = Vue.$loading.show()
      let res= await orderService.report_company_activity(param)
      loader.hide()
      return res
    }
  },
  mutations: {
    set_ls_order (state, { items, pages,per_page }) {
      state.ls_order = items
      state.page_count_order = pages
      state.per_page_order=per_page
    },
    set_page (state, page) {
      state.page_order = page
    },
    set_filter (state, filter) {
      state.filter_order = filter
    }
  }
}
