import Axios from 'axios'
import apiUrl from '../utils/api'
import { authHeader } from '../helpers'
import { Business } from '@/helpers/business.js'

export const orderService = {
  get_by_id,
  create,
  update,
  delete_obj,
  recover_obj,
  bill_pdf,
  report_company_revenue,
  report_company_activity,
  get_ls_order,
  get_ls_object,
  get_ls_client,
  release_object,
  pdf_product_im_ex,
  excel_product_im_ex,
  merge_object,
  clone_object,
  report_client_debt,
  report_supply_debt,
  code_unique
}

function code_unique(param){
  return Axios.get(`${apiUrl}/order/code-unique?code=${param.code}&company_id=${param.company_id}&branch_id=${param.branch_id}`,{headers: authHeader()})
}

function merge_object(param){
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/order/merge-object`,requestOptions)
}

function clone_object(param){
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/order/clone-object`,requestOptions)
}

function get_ls_order(param) {
  const requestOptions = {
      headers: authHeader(),
      params:param
  }
  return Axios.get(`${apiUrl}/order/list`,requestOptions)
}

function get_ls_object(param) {
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/order/list-object`,requestOptions)
}

function get_ls_client(param) {
  const requestOptions = {
    headers: authHeader(),
    params:param
  }
  return Axios.get(`${apiUrl}/order/list-client`,requestOptions)
}

function delete_obj(id) {
  return Axios.delete(`${apiUrl}/order/delete/${id}`, {headers: authHeader()})
}
function recover_obj(id) {
  return Axios.delete(`${apiUrl}/order/recover/${id}`,{headers: authHeader()})
}

function release_object(param) {
  return Axios.put(`${apiUrl}/order/release/${param.order_id}`,param,{headers:authHeader()})
}

function create(param) {
  debugger
  if(param.business==Business.hostel){
    return Axios.post(`${apiUrl}/order-hostel/create`,param,{headers:authHeader()})
  }else if(param.business==Business.bida||param.business==Business.karaoke
    ||param.business==Business.cafe||param.business==Business.food){
    return Axios.post(`${apiUrl}/order-bida-kara-cafe-food/create`,param,{headers:authHeader()})
  }else if(param.business==Business.spa){
    return Axios.post(`${apiUrl}/order-spa/create`,param,{headers:authHeader()})
  }else if(param.business==Business.football||param.business==Business.hotel){
    return Axios.post(`${apiUrl}/order-football-hotel/create`,param,{headers:authHeader()})
  }else if(param.business==Business.oto){
    return Axios.post(`${apiUrl}/order-oto/create`,param,{headers:authHeader()})
  }else if(param.business==Business.pharmacy){
    return Axios.post(`${apiUrl}/order-pharmacy/create`,param,{headers:authHeader()})
  }else if(param.business==Business.pawn||param.business==Business.tole||
    param.business==Business.water){
    return Axios.post(`${apiUrl}/order-pawn-tole-water/create`,param,{headers:authHeader()})
  }else if(param.business==Business.vlxd){
    return Axios.post(`${apiUrl}/order-vlxd/create`,param,{headers:authHeader()})
  }else{
    return Axios.post(`${apiUrl}/order-dentist/create`,param,{headers:authHeader()})
  }
}

function update(param) {
  if(param.business==Business.hostel){
    return Axios.put(`${apiUrl}/order-hostel/update/${param.order.id}`,param,{headers:authHeader()})
  }else if(param.business==Business.bida||param.business==Business.karaoke
    ||param.business==Business.cafe||param.business==Business.food){
    return Axios.put(`${apiUrl}/order-bida-kara-cafe-food/update/${param.order.id}`,param,{headers:authHeader()})
  }else if(param.business==Business.spa){
    return Axios.put(`${apiUrl}/order-spa/update/${param.order.id}`,param,{headers:authHeader()})
  }else if(param.business==Business.football||param.business==Business.hotel){
    return Axios.put(`${apiUrl}/order-football-hotel/update/${param.order.id}`,param,{headers:authHeader()})
  }else if(param.business==Business.oto){
    return Axios.put(`${apiUrl}/order-oto/update/${param.order.id}`,param,{headers:authHeader()})
  }else if(param.business==Business.pharmacy){
    return Axios.put(`${apiUrl}/order-pharmacy/update/${param.order.id}`,param,{headers:authHeader()})
  }else if(param.business==Business.pawn||param.business==Business.tole||
    param.business==Business.water){
    return Axios.put(`${apiUrl}/order-pawn-tole-water/update/${param.order.id}`,param,{headers:authHeader()})
  }else if(param.business==Business.vlxd){
    return Axios.put(`${apiUrl}/order-vlxd/update/${param.order.id}`,param,{headers:authHeader()})
  }else{
    return Axios.put(`${apiUrl}/order-dentist/update/${param.order.id}`,param,{headers:authHeader()})
  }
}

function get_by_id(id) {
  return Axios.get(`${apiUrl}/order/by-id/${id}`,{headers: authHeader()})
}

function bill_pdf(param) {
  const requestOptions = {
    method: 'GET',
    responseType: 'arraybuffer',
    headers: authHeader()
  }
  if(param.business==Business.cafe){
    return Axios.get(`${apiUrl}/order-cafe/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.dentist){
    return Axios.get(`${apiUrl}/order-dentist/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.karaoke){
    return Axios.get(`${apiUrl}/order-karaoke/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.food){
    return Axios.get(`${apiUrl}/order-food/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.pawn){
    return Axios.get(`${apiUrl}/order-pawn/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.football){
    return Axios.get(`${apiUrl}/order-football/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.tole){
    return Axios.get(`${apiUrl}/order-tole/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.hotel){
    return Axios.get(`${apiUrl}/order-hotel/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.bida){
    return Axios.get(`${apiUrl}/order-bida/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.water){
    return Axios.get(`${apiUrl}/order-water/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.spa){
    return Axios.get(`${apiUrl}/order-spa/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.oto){
    return Axios.get(`${apiUrl}/order-oto/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.pharmacy){
    return Axios.get(`${apiUrl}/order-pharmacy/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }else if(param.business==Business.vlxd){
    return Axios.get(`${apiUrl}/order-vlxd/bill/${param.id}?type=${param.type}&lang=${param.lang}`,requestOptions)
  }
}

function report_company_activity(param){
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
    params:param
  }

  if(param.business==Business.cafe){
    return Axios.post(`${apiUrl}/cafe/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.dentist){
    return Axios.post(`${apiUrl}/dentist/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.karaoke){
    return Axios.post(`${apiUrl}/karaoke/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.food){
    return Axios.post(`${apiUrl}/food/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.pawn){
    return Axios.post(`${apiUrl}/pawn/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.football){
    return Axios.post(`${apiUrl}/football/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.tole){
    return Axios.post(`${apiUrl}/tole/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.hotel){
    return Axios.post(`${apiUrl}/hotel/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.hostel){
    return Axios.post(`${apiUrl}/hostel/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.bida){
    return Axios.post(`${apiUrl}/bida/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.water){
    return Axios.post(`${apiUrl}/water/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.spa){
    return Axios.post(`${apiUrl}/spa/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.oto){
    return Axios.post(`${apiUrl}/oto/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.vlxd){
    return Axios.post(`${apiUrl}/vlxd/pdf-company-activity`,param,requestOptions)
  }else if(param.business==Business.pharmacy){
    return Axios.post(`${apiUrl}/pharmacy/pdf-company-activity`,param,requestOptions)
  }
}

function report_company_revenue(param){
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
    params:param
  }

  return Axios.post(`${apiUrl}/report/pdf-company-revenue`,param,requestOptions)
}

function report_client_debt(param){
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
    params:param
  }

  return Axios.post(`${apiUrl}/report/pdf-client-debt`,param,requestOptions)
}

function report_supply_debt(param){
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
    params:param
  }

  return Axios.post(`${apiUrl}/report/pdf-supply-debt`,param,requestOptions)
}

function pdf_product_im_ex(param){
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
    params:param
  }
  return Axios.post(`${apiUrl}/product/pdf-im-ex-re/${param.data.company_id}`,param,requestOptions)
}

function excel_product_im_ex(param){
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
    params:param
  }
  debugger
  return Axios.post(`${apiUrl}/product/excel-im-ex-re/${param.company_id}`,param,requestOptions)
}
